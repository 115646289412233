// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TButton from '../../../common/button';
import wChange from '../../../../../assets/imgs/lotteryDetail/wChangeShort.png';
import RainbowText from '../../../common/rainbowText';
import './index.scss';
import PrizeItem from './PrizeItem';
import RecommendLottery from './RecommendLottery';
import { useHistory } from 'react-router-dom';
import OnChallengeText from '../../../common/OnChallengeText';
import PreviewPhoto from '../../purchaseHistoryDetail/PurchaseHistoryDetailPC/PreviewPhoto';
import { ON_CHALLENGE } from '../../../../constants';
import { facebookIcon, GUIDE, TOP_IMAGES, twitterIcon } from '../../../../../assets/imgs';

export const StyledTitle = styled.div`
  background: url(${wChange});
  background-size: cover;
`;

// const Line = styled.div`
//   background-image: url(${TOP_IMAGES.LineShort});
//   height: 6px;
//   width: 163px;
//   margin: 0 auto;
//   margin-bottom: 55px;
// `;

export default function ScratchResultPC(props) {
  const history = useHistory();
  const {
    scratchResult,
    recommendLottery,
    gotoReBuy,
    gotoLotteryDetail,
    totalPrize,
    handleShare,
    getWidth,
    isQuantityWChance,
  } = props;

  const __prizeNormalData =
    scratchResult && scratchResult.filter((item) => item.rank_name !== ON_CHALLENGE);

  const __wChanceData =
    scratchResult && scratchResult.find((item) => item.rank_name === ON_CHALLENGE);

  const [isShowReview, setShowReview] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);

  const eleWrap = document?.getElementById('share-sns-wrapper')?.offsetWidth;

  const [width, setWidth] = useState(eleWrap);
  useEffect(() => {
    setWidth(eleWrap);
  }, [eleWrap]);
  useEffect(() => {
    if (width) {
      getWidth(width);
    }
  }, [width]);
  useEffect(() => {
    function handleResize() {
      setWidth(document.getElementById('share-sns-wrapper')?.offsetWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const pathLotteryTrial = '/result/trial';
  const pathSite = history.location.pathname;

  return (
    <>
      <div className="main-content resultPC">
        <div id="share-sns-wrapper">
          <div className="title-lottery-result">抽選結果</div>
          {/* <Line /> */}
          {__prizeNormalData && (
            <div className="list-prize-wrapper d-flex flex-wrap">
              {__prizeNormalData.map((item, index) => (
                <PrizeItem
                  data={item}
                  key={index}
                  onClick={() => {
                    setShowReview(true);
                    setSelectedPrize(item);
                  }}
                />
              ))}
            </div>
          )}

          {__wChanceData && (
            <div
              className="wChance-wrapper"
              style={{ marginBottom: pathLotteryTrial === pathSite ? '180px' : 'unset' }}
            >
              <div
                className="wchance-all"
                style={{
                  backgroundColor:
                    (pathLotteryTrial === pathSite && isQuantityWChance === 0) ||
                    (pathLotteryTrial !== pathSite && !__wChanceData?.quantity)
                      ? 'rgba(120,120,120,0.4)'
                      : '',
                }}
              >
                <div className="wchance-full">
                  <StyledTitle className="title">
                    <OnChallengeText isInResult />
                  </StyledTitle>
                  <div className="wchance-content">
                    <PrizeItem
                      data={__wChanceData}
                      type={ON_CHALLENGE}
                      totalPrize={totalPrize}
                      onClick={() => {
                        setShowReview(true);
                        setSelectedPrize(__wChanceData);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <PreviewPhoto
          show={isShowReview}
          onHide={() => setShowReview(false)}
          data={selectedPrize}
          isInResultPage
        />

        {pathLotteryTrial !== pathSite && (
          <div className="text-center" style={{ marginTop: '100px' }}>
            <TButton
              id="reBuy"
              title="もう一度購入する"
              className="info has-long-text"
              onClick={gotoReBuy}
            />
            <div
              className="d-flex justify-content-center main-content"
              style={{ marginBottom: 40 }}
            >
              <TButton
                id="goBack"
                title="商品ページへ戻る"
                className="primary-yellow has-long-text"
                onClick={gotoLotteryDetail}
              />
              <TButton
                title="Myコレクションを見る"
                className="primary-yellow has-long-text"
                onClick={() => history.push('/my-collection')}
              />
            </div>
          </div>
        )}

        {pathLotteryTrial !== pathSite && (
          <div className="share-sns">
            <div className="title">
              <img src={GUIDE.iconLeft} alt="icon" className="icon" />
              <div className="text-share">結果をシェアする</div>
              <img src={GUIDE.iconRight} alt="icon" className="icon" />
            </div>
            <div className="icon-wrapper">
              <img
                src={facebookIcon}
                alt="icon"
                className="icon icon-margin"
                onClick={() => handleShare('fb')}
              />
              <img
                src={twitterIcon}
                alt="icon"
                className="icon"
                onClick={() => handleShare('tw')}
              />
            </div>
          </div>
        )}

        {pathLotteryTrial === pathSite && (
          <div className="text-center">
            <div
              className="d-flex justify-content-center main-content"
              style={{ marginBottom: 40 }}
            >
              <TButton
                id="goBack"
                title="もう一度お試しクジする"
                className="primary-yellow has-long-text"
                onClick={() => history.push('/purchase/trial')}
              />
              <TButton
                title="商品ページへ戻る"
                className="primary has-long-text"
                onClick={() => history.push('/trial')}
              />
            </div>
          </div>
        )}
      </div>

      {recommendLottery.length ? (
        <div className="recommend-lottery-wrapper">
          <div style={{ marginBottom: 56 }} className="text-center">
            <div id="recommend-text" className="my-color-black-200">
              RECOMMEND
            </div>
            <div className="line" />
            <RainbowText text="おすすめくじ" />
          </div>
          <div>
            <RecommendLottery recommendLottery={recommendLottery} />
          </div>
        </div>
      ) : null}
    </>
  );
}
