// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { Button } from './Styles';
import { IoIosArrowUp } from 'react-icons/io';
import './styles.scss';

const ScrollButton = ({ className = '' }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Button className={className}>
      <div
        className="wrap-scroll-to-top"
        onClick={scrollToTop}
        style={visible ? { display: 'block' } : { display: 'none' }}
      >
        <div className="icon-scroll">
          <IoIosArrowUp />
        </div>
        <div className="text-scroll">{`最初に\n戻る`}</div>
      </div>
    </Button>
  );
};

export default ScrollButton;
