// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';

function NoteSP(props) {
  const { t } = useTranslation();
  return (
    <div className="my-note-sp">
      <div className="d-flex align-items-center">
        <div className="icon-pre-text" />
        <p className="mb-0">
          <strong>{t('lotteryEventTermsToPay')}</strong>
        </p>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>画像はイメージです。実際の商品とはデザイン・仕様が一部異なる場合がございます。</div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          本商品の販売は予告なく内容の変更や、販売期間の終了・延長・再販売をする場合があります。
        </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>商品は基本的に同梱となりますが、数が多い場合はその限りではありません。 </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          大量生産による若干の個体差（製品イメージを大きく損なわない程度の微細なキズ・縫製・糸くずなど）に関しましては交換対象外となります。
        </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>開封や一度でも使用したものなどは交換対象外となります。</div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          注文状況や商品によって発送時期が前後する場合があります。出荷の順番は購入順ではございませんのでご了承ください。{' '}
        </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          各賞の当せん確率は購入者全体（くじ全体）での当せん確率を表記しており、また抽選1回毎に表記の確率で抽選がおこなわれます。お客様ごとの当せん確率ではないため、必ずしも購入個数と当せん確率が伴うものではございません。予めご了承ください。
        </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          ＋On
          Challengeに当せん個数の記載がない場合は、個数を公表しておりません。お問い合わせをいただいても回答できかねますので、ご了承ください。
        </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>サイン入り商品を第三者へ譲渡・オークション等へ転売する行為は禁止いたします。</div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          ＋On
          Challengeで当せんしたイベント参加・商品受け取り権利の、譲渡・委任・転売は固く禁止いたします。
        </div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>すべての商品において、高額の転売は禁止いたします。</div>
      </div>
      <div className="ul-note">
        <div className="ul-note-icon">※</div>
        <div>
          弊社サイト以外でくじ商品を購入された場合、弊社は一切責任を負いません。購入代金の返金もいたしかねます。
        </div>
      </div>
    </div>
  );
}

export default NoteSP;
