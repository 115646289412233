// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { AuthApis } from '../../services/auth';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import { validateEmail } from '../../validations/validateEmail';
import { validatePasswordLogin } from '../../validations/validatePassword';
import { login } from '../../store/features/auth';
import TopHeader from '../../components/common/topHeader';
import BoxRequired from '../../components/common/boxRequired';
import TButton from '../../components/common/button';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import useMessage from '../../components/common/toast/UseMessage';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import queryString from 'query-string';
import { AiOutlineRight } from 'react-icons/ai';
import { listsErrorNotShowToast } from '../../constants';
import JSCookie from 'js-cookie';

export default function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const dispatch = useAppDispatch();
  const [validationMsg, setValidationMsg] = useState({});
  const [validationMsgApi, setValidationMsgApi] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(0);
  const { openMessageError, openMessage } = useMessage();

  const handleMoveToRegister = () => {
    history.push('/register');
  };

  const handleMoveToForgotPassword = () => {
    history.push('/forgot-password');
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setValidationMsgApi('');
  };

  const authToken = JSCookie.get('token');
  useEffect(() => {
    if (authToken) {
      history.push('/');
    }
  }, []);

  const validateAll = () => {
    const error = {
      email: '',
      password: '',
    };
    error.email = validateEmail(email.trim());
    error.password = validatePasswordLogin(password);
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i++) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    const formData = {
      email: email,
      password: password,
      remember_me: checked,
    };
    try {
      const res = await AuthApis.login(formData);
      dispatch(login(res.data));
      if (search?.callbackUrl) {
        history.push(search?.callbackUrl);
      } else {
        history.push('/');
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        openMessage({ message: `${t('textErrorManyAttempts')}`, variant: 'error' });
      } else {
        setValidationMsgApi(error.response.data.message);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const responseSocial = async (response, type) => {
    const { accessToken } = response;
    dispatch(showLoading());
    try {
      if (accessToken) {
        const formData = {
          provider: type,
          access_token: accessToken,
        };
        const res = await AuthApis.loginSocial(formData);
        const { token } = res.data;
        if (token) {
          dispatch(login(res.data));
          if (search?.callbackUrl) {
            history.push(search?.callbackUrl);
          } else {
            history.push('/');
          }
        } else {
          history.push({
            pathname: '/register-social',
            state: { data: res.data },
          });
        }
      } else {
        openMessage({ message: 'ログインが失敗しました。', variant: 'error' });
        history.push('/login');
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleLoginTwitter = async () => {
    dispatch(showLoading());
    try {
      const res = await AuthApis.loginTwitter();
      const { login_url } = res.data;
      if (login_url) {
        window.location = login_url;
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <TopHeader title={t('headerLogin')} />
      <div className="login">
        <div className="main-content">
          <form
            className="form-login"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="title-login">{t('titleLogin')}</div>
            <div className="mt-4">
              <BoxRequired text={t('txtEmail')} />
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                maxLength="255"
                className="form-control"
                placeholder="test@example.com"
                autoComplete="off"
                value={email}
                onChange={handleChangeEmail}
              ></input>
            </div>
            <p className="txt_error">{validationMsg.email}</p>
            <div className="mt-4">
              <BoxRequired text={t('txtPassword')} />
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="半角英数字8～16文字"
                autoComplete="off"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              ></input>
            </div>
            <p className="txt_error">{validationMsg.password}</p>
            <p className="txt_error">{validationMsgApi}</p>
            <div className="move-to-page mb16" onClick={handleMoveToForgotPassword} tabIndex="0">
              <p className="text-move-to-page">{t('buttonForgotPassword')}</p>
              <AiOutlineRight className="icon-move-to" />
            </div>
            <div className="form-submit">
              <TButton title={t('login')} className="mt-4 btn-login info" type="submit" />
              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={() => {
                    checked === 0 ? setChecked(1) : setChecked(0);
                  }}
                />
                <label className="form-check-label">{t('checkboxLogin')}</label>
              </div>
            </div>
          </form>

          <div className="form-login-social">
            <div className="title-login-social">{t('headerLoginSocial')}</div>
            <div className="btn-social">
              <FacebookLogin
                appId={process.env.APP_ID_FACEBOOK}
                fields="name,email,picture"
                callback={(res) => responseSocial(res, 'facebook')}
                textButton={t('btnFacebook')}
                cssClass="btnFacebook"
                icon={<FaFacebookF className="icon-social" />}
                isMobile={false}
              />
              <button type="button" className="btnTwitter" onClick={handleLoginTwitter}>
                <FaTwitter className="icon-social" />
                {t('btnTwitter')}
              </button>
              <GoogleLogin
                clientId={process.env.CLIENT_ID_GOOGLE}
                onSuccess={(res) => responseSocial(res, 'google')}
                cookiePolicy={'single_host_origin'}
                className="btnGoogle"
                icon={false}
              >
                <FcGoogle className="icon-google" />
                {t('btnGoogle')}
              </GoogleLogin>
            </div>
          </div>
          <div className="move-to-register">
            <TButton
              title={t('buttonMoveToRegister')}
              className="btn-move-to-register primary-yellow btn-register"
              onClick={handleMoveToRegister}
            />
          </div>
        </div>
      </div>
      <ScrollButton />
    </>
  );
}
