// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import '../forgotPassword/styles.scss';
import TButton from '../../components/common/button';
import ModalChangePassword from './ModalChangePassword';
import { AuthApis } from '../../services/auth';
import { listsErrorNotShowToast } from '../../constants';
import { useHistory } from 'react-router-dom';

export default function ChangePassword() {
  const { openMessageError, openMessage } = useMessage();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();

  const emailUser = history?.location?.state;

  const handleSubmit = async () => {
    dispatch(showLoading());
    try {
      const res = await AuthApis.changePassword({ email: emailUser });
      setModalShow(true);
      const { data } = res;
      const messageSuccess = data.message;
      openMessage({ message: `${messageSuccess}`, variant: 'success' });
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div>
      <TopHeader title={t('headerChangePassword')} />
      <form
        className="main-content forgot-password"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="form-forgot-password">
          <div className="title-change-email">{t('titleChangePassword')}</div>
          <div className="info-change-password">
            パスワードを再設定するためのメールを送信します。
            <br />
            「送信する」ボタンをクリックしてください。
            <br />
            ※「support@beskuzi.com」からメールが届きます。
            <br />
            受信できるように ドメインの設定 をお願いします。
          </div>
        </div>
        <div className="text-center btn-change-email">
          <TButton
            title={t('buttonChangePassword')}
            className="btn primary-yellow btn-sp change-email"
            type="submit"
          />
        </div>
      </form>
      <ModalChangePassword show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}
