// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineRight } from 'react-icons/ai';
import { getColorStatus } from '../../../../utils/setColorStatus';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { STATUS_DIGITAL_PRIZE } from '../../../../constants';
export default function PurchaseHistorySP(props) {
  const { data } = props;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="main-content purchase-history-sp">
      {data &&
        data.map((item, index) => (
          <div key={index}>
            <Table bordered borderless>
              <thead>
                <tr>
                  <th>
                    <div>
                      {t('titleTimePurchase')} : {item.time_buy}
                    </div>
                    <div>
                      <div className={`status ${getColorStatus(item.shipping_status)}`}>
                        {item.shipping_status_label}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <div
                      className="name-lottery"
                      onClick={() => history.push(`/lottery/${item.lottery_event_id}`)}
                    >
                      {item.name_lottery}
                    </div>
                    <div>
                      <div>
                        <div
                          className="time-shipping"
                          style={
                            item.shipping_status == STATUS_DIGITAL_PRIZE
                              ? { marginTop: 0, height: '25px' }
                              : {}
                          }
                        >
                          {item.shipping_status !== STATUS_DIGITAL_PRIZE && (
                            <>
                              <label className="title-time-shipping">
                                {t('titleTimeShipping')}
                              </label>
                              <label>{item.estimated_delivery_date}</label>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="move-to-detail"
                        onClick={() => history.push(`/order/history/${item.id}`)}
                        style={
                          item.shipping_status == STATUS_DIGITAL_PRIZE ? { right: 'inherit' } : {}
                        }
                      >
                        {t('buttonMoveToPurchaseDetail')}
                        <AiOutlineRight className="icon-to-detail" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ))}
    </div>
  );
}
