// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineRight } from 'react-icons/ai';
import { getColorStatus } from '../../../../utils/setColorStatus';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { STATUS_DIGITAL_PRIZE } from '../../../../constants';
export default function PurchaseHistoryPC(props) {
  const { data } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="main-content purchase-history-pc">
      <Table bordered borderless>
        <thead>
          <tr>
            <th>
              <div className="time-purchase">{t('titleTimePurchase')}</div>
            </th>
            <th>
              <div className="td-purchase-history">{t('titleNameLottery')}</div>
            </th>
            <th>
              <div className="td-purchase-history">{t('titleTimeShipping')}</div>
            </th>
            <th>
              <div className="confirm-detail">{t('titleConfirmDetailPurchase')}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td className="delivery-date">
                  <div>{item.time_buy}</div>
                </td>
                <td>
                  <div
                    className="name-lottery"
                    onClick={() => history.push(`/lottery/${item.lottery_event_id}`)}
                  >
                    {item.name_lottery}
                  </div>
                </td>
                <td>
                  <div className="time-shipping">
                    <div className={`status ${getColorStatus(item.shipping_status)}`}>
                      {item.shipping_status_label}
                    </div>
                    {item.shipping_status !== STATUS_DIGITAL_PRIZE && (
                      <div className="delivery-date">{item.estimated_delivery_date}</div>
                    )}
                  </div>
                </td>
                <td>
                  <div
                    className="move-to-detail"
                    onClick={() => history.push(`/order/history/${item.id}`)}
                  >
                    {t('buttonMoveToPurchaseDetail')} <AiOutlineRight className="icon-to-detail" />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
