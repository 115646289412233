// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import { AuthApis } from '../../services/auth';
import { login } from '../../store/features/auth';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import './styles.scss';
import { listsErrorNotShowToast } from '../../constants';
export default function confirmLoginSocial() {
  const history = useHistory();
  const location = useLocation();
  const data = location?.state;
  const dataFacebook = data?.data;

  const { openMessageError } = useMessage();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleSubmit = async () => {
    dispatch(showLoading());
    const formData = {
      email: dataFacebook.email,
      provider_name: dataFacebook.provider_name,
      provider_id: dataFacebook.provider_id,
    };
    try {
      const res = await AuthApis.registerSocial(formData);
      if (res.status === 200) {
        dispatch(login(res.data));
        if (location.state) {
          history.push('/');
        }
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleCancel = () => {
    history.push('/');
  };
  return (
    <>
      {!data ? (
        <Redirect to="/login" />
      ) : (
        <div>
          <TopHeader title={t('headerRegister')} />
          <div className="main-content">
            <div className="row">
              <div className="col-12 col-md-8">
                <p>登録確認を押したら、{dataFacebook.email}で会員登録をします。</p>
                {/*<div className="form-check mt-3">
                  <input className="form-check-input" type="checkbox" defaultChecked />
                  <label className="form-check-label">{t('receiveEmail')}</label>
                  </div>*/}
                <div>
                  <button className="btn btn-secondary mt-2 mr15" onClick={handleCancel}>
                    {t('cancel')}
                  </button>
                  <button type="submit" className="btn btn-primary mt-2" onClick={handleSubmit}>
                    {t('buttonRegisterSocial')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
