// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import BoxRequired from '../../components/common/boxRequired';
import {
  validateAddress1,
  validateConfirmEmailDelivery,
  validateDistrict,
  validateEmailDelivery,
  validateFullName,
  validateFurigana,
  validatePhone,
  validateProvince,
  validateZipCode,
} from '../../validations/validateAddress';
import { UserInfoApis } from '../../services/userInfo';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import {
  listsErrorNotShowToast,
  PREFECTURES,
  regexFullWidth,
  URL_PREFECTURES,
} from '../../constants';
import useMessage from '../../components/common/toast/UseMessage';
import TButton from '../../components/common/button';
import TopHeader from '../../components/common/topHeader';
import { useLocation } from 'react-router';
import { useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import _ from 'lodash';

export default function EditAddress() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameFurigana, setFirstNameFurigana] = useState('');
  const [lastNameFurigana, setLastNameFurigana] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [phone, setPhone] = useState('');
  const [emailDelivery, setEmailDelivery] = useState('');
  const [emailDeliveryConfirm, setEmailDeliveryConfirm] = useState('');
  const [checked, setChecked] = useState(0);
  const [checkedPolicy, setCheckedPolicy] = useState(false);
  const [validationMsg, setValidationMsg] = useState({});
  const location = useLocation();
  const hideCheckbox = location?.state?.hideCheckbox;
  const searchAddressId = queryString.parse(history?.location?.search);
  const searchParam = {
    adult_purchase: searchAddressId?.adult_purchase,
    quantity: searchAddressId?.quantity,
    payment_method: searchAddressId?.payment_method,
    point: searchAddressId?.point,
  };
  const search = queryString.stringify(searchParam);

  const [isPostalCode, checkPostalCode] = useState(false);

  const validateAll = () => {
    const error = {
      lastName: '',
      firstName: '',
      firstNameFurigana: '',
      lastNameFurigana: '',
      postalCode: '',
      address1: '',
      phone: '',
      emailDelivery: '',
      emailDeliveryConfirm: '',
      district: '',
      province: '',
    };
    error.lastName = validateFullName(lastName.trim());
    error.firstName = validateFullName(firstName.trim());
    error.firstNameFurigana = validateFurigana(firstNameFurigana.trim());
    error.lastNameFurigana = validateFurigana(lastNameFurigana.trim());
    error.postalCode = validateZipCode(postalCode.trim());
    error.district = validateDistrict(district);
    error.province = validateProvince(province.trim());
    error.address1 = validateAddress1(address1.trim());
    error.phone = validatePhone(phone.trim());
    error.emailDelivery = validateEmailDelivery(emailDelivery.trim());
    error.emailDeliveryConfirm = validateConfirmEmailDelivery(
      emailDelivery.trim(),
      emailDeliveryConfirm.trim(),
    );
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i++) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const validatePostalCode = () => {
    if (!isPostalCode) {
      setValidationMsg({
        ...validationMsg,
        postalCode: '郵便番号が正しくありません。',
      });
    } else {
      setValidationMsg({
        ...validationMsg,
        postalCode: '',
      });
    }
  };

  const handleSubmit = async () => {
    const isValid = validateAll();

    if (!isValid) return;
    validatePostalCode();
    if (!isPostalCode) return;
    dispatch(showLoading());
    const formData = {
      postal_code: postalCode,
      district: district,
      province: province,
      address1: address1,
      address2: address2,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      first_name_furigana: firstNameFurigana,
      last_name_furigana: lastNameFurigana,
      email_delivery_address: emailDelivery,
      email_delivery_address_confirmation: emailDeliveryConfirm,
      address_default_flag: checked,
    };

    try {
      let res = {};
      if (orderId) {
        res = await UserInfoApis.updateDeliveryAddressPurchase({
          ...formData,
          order_id: orderId,
        });
      } else if (id) {
        res = await UserInfoApis.createAddressPurchase(formData);
      } else {
        res = await UserInfoApis.updateDeliveryAddress(formData);
      }
      const { data } = res;
      const addressId = data.address_id;

      if (addressId) {
        history.push(`/purchase/${id}?${search}&address_id=${addressId}`);
      } else if (orderId) {
        history.push(`/order/history/${orderId}`);
        const messageSuccess = data.message;
        openMessage({ message: `${messageSuccess}`, variant: 'success' });
      } else if (id) {
        history.push(`/purchase/${id}?${search}`);
        const messageSuccess = data.message;
        openMessage({ message: `${messageSuccess}`, variant: 'success' });
      } else {
        history.push(`/my-page`);
        const messageSuccess = data.message;
        openMessage({ message: `${messageSuccess}`, variant: 'success' });
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getUserInfo = async () => {
    const formData = {
      info: 'profile',
    };
    const formDataHasAddressId = {
      info: 'purchase',
      lottery_event_id: id,
      address_id: Number(searchAddressId?.address_id),
    };
    const formDataHasOrderId = {
      info: 'profile',
      order_id: orderId,
    };
    const convertData = () => {
      if (orderId) {
        return formDataHasOrderId;
      } else if (searchAddressId?.address_id) {
        return formDataHasAddressId;
      }
      return formData;
    };
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(convertData());
      const dataProfile = res?.data;
      setFirstName(dataProfile.first_name ? dataProfile.first_name.trim() : '');
      setLastName(dataProfile.last_name ? dataProfile.last_name.trim() : '');
      setFirstNameFurigana(
        dataProfile.first_name_furigana ? dataProfile.first_name_furigana.trim() : '',
      );
      setLastNameFurigana(
        dataProfile.last_name_furigana ? dataProfile.last_name_furigana.trim() : '',
      );
      setPostalCode(dataProfile.postal_code ? dataProfile.postal_code.trim().replace('-', '') : '');
      setDistrict(dataProfile.district ? dataProfile.district.trim() : '');
      setProvince(dataProfile.province ? dataProfile.province.trim() : '');
      setAddress1(dataProfile.address1 ? dataProfile.address1.trim() : '');
      setAddress2(dataProfile.address2 ? dataProfile.address2.trim() : '');
      setPhone(dataProfile.phone ? dataProfile.phone.trim() : '');
      setEmailDelivery(dataProfile.email ? dataProfile.email_delivery_address.trim() : '');
      setEmailDeliveryConfirm(
        dataProfile.email_delivery_address ? dataProfile.email_delivery_address.trim() : '',
      );
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getUserInfo();
    checkPostalCode(true);
  }, []);

  const _fetch = (url, yubin7) => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => _setAddr(text, yubin7))
      .catch((error) => console.error(error));
  };

  const _setAddr = (text, yubin7) => {
    const matcher = text.match(/({".*"]})/);
    if (matcher) {
      const json = JSON.parse(matcher[0]);
      const addr = json[yubin7];
      if (addr) {
        checkPostalCode(true);
      } else {
        checkPostalCode(false);
      }
      if (addr && addr[0] && addr[1]) {
        const index = addr[0] - 1;
        setProvince(PREFECTURES[index]['label']);
        setDistrict(addr[1]);
      }
    }
  };

  const _chk7 = (val) => {
    if (val.length === 7) {
      return val;
    }
    return null;
  };

  const _getAddr = (yubin7) => {
    const yubin3 = yubin7.substr(0, 3);
    _fetch(`${URL_PREFECTURES}/${yubin3}.js`, yubin7);
  };

  const _handleTextChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    setPostalCode(inputValue);
    if (inputValue) {
      const a = inputValue.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 65248));
      const b = a.match(/\d/g);
      const c = b.join('');
      const yubin7 = _chk7(c);
      if (yubin7) {
        _getAddr(inputValue);
      }
    }
  };

  useEffect(() => {
    if (validationMsg) {
      const keys = Object.keys(_.pickBy(validationMsg, _.size))[0];
      if (keys) {
        document.getElementById(keys).scrollIntoView();
      }
    }
  }, [validationMsg]);
  return (
    <div className="edit-address">
      <TopHeader title={t('headerEditAddress')} />
      <div className="main-content">
        <form
          className="form-edit"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-edit-item">
            <div className="form-edit-title">
              <BoxRequired text={t('textFullName')} />
            </div>
            <div className="form-edit-input">
              <div className="form-input-name space-input">
                <input
                  className="form-control input-group input-name"
                  placeholder="姓"
                  maxLength="15"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  id="lastName"
                />
                {validationMsg.lastName && (
                  <div className="txt_error">{validationMsg.lastName?.replace('param', '姓')}</div>
                )}
              </div>

              <div className="form-input-name">
                <input
                  className="form-control input-group input-name"
                  placeholder="名"
                  maxLength="15"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  id="firstName"
                />
                {validationMsg.firstName && (
                  <div className="txt_error">{validationMsg.firstName?.replace('param', '名')}</div>
                )}
              </div>
            </div>
          </div>

          <div className="form-edit-item">
            <div className="form-edit-title">
              <BoxRequired text={t('textFurigana')} />
            </div>
            <div className="form-edit-input">
              <div className="form-input-name space-input">
                <input
                  className="form-control input-group input-name"
                  placeholder="せい"
                  maxLength="15"
                  value={lastNameFurigana}
                  onChange={(event) => setLastNameFurigana(event.target.value)}
                  id="lastNameFurigana"
                />
                {validationMsg.lastNameFurigana && (
                  <div className="txt_error">
                    {validationMsg.lastNameFurigana?.replace('param', 'せい')}
                  </div>
                )}
              </div>
              <div className="form-input-name">
                <input
                  className="form-control input-group input-name"
                  placeholder="めい"
                  maxLength="15"
                  value={firstNameFurigana}
                  onChange={(event) => setFirstNameFurigana(event.target.value)}
                  id="firstNameFurigana"
                />
                {validationMsg.firstNameFurigana && (
                  <div className="txt_error">
                    {validationMsg.firstNameFurigana?.replace('param', 'めい')}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-edit-item">
            <div className="form-edit-title title-address">
              <BoxRequired text={t('textCity')} />
            </div>

            <div className="form-edit-input">
              <div className="form-input-name space-input">
                <div className="textAddress">郵便番号</div>
                <input
                  className="form-control input-group input-name"
                  placeholder="半角数字"
                  maxLength="7"
                  value={postalCode}
                  onChange={_handleTextChange}
                  onBlur={validatePostalCode}
                  id="postalCode"
                />
                {validationMsg.postalCode && (
                  <div className="txt_error">{validationMsg.postalCode}</div>
                )}
              </div>
              <div className="form-input-name">
                <div className="textAddress">都道府県</div>
                <select
                  className="form-select input-group selectCity"
                  value={province}
                  maxLength="30"
                  onChange={(event) => setProvince(event.target.value)}
                  id="province"
                >
                  <option defaultValue=""></option>
                  {PREFECTURES.map((item, index) => (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {validationMsg.province && (
                  <div className="txt_error">{validationMsg.province}</div>
                )}
              </div>
            </div>
          </div>
          <div className="form-edit-notify">
            <div className="text-note">{t('alertZipCode')}</div>
          </div>

          <div className="form-edit-notify">
            <div className="textAddress">市区町村</div>
            <div className="from-input-edit-item form-edit-input-max-width">
              <input
                className="form-control input-group"
                maxLength="30"
                value={district}
                onChange={(event) => setDistrict(event.target.value)}
                id="district"
              />
              {validationMsg.district && <div className="txt_error">{validationMsg.district}</div>}
            </div>
          </div>

          <div className="form-edit-notify">
            <div className="textAddress">番地</div>
            <div className="from-input-edit-item form-edit-input-max-width">
              <input
                className="form-control input-group"
                maxLength="50"
                value={address1}
                onChange={(event) => setAddress1(event.target.value)}
                id="address1"
              />
              {validationMsg.address1 && <div className="txt_error">{validationMsg.address1}</div>}
            </div>
          </div>

          <div className="form-edit-notify">
            <div className="textAddress">建物名・部屋番号</div>
            <div className="from-input-edit-item form-edit-input-max-width">
              <input
                className="form-control input-group"
                maxLength="50"
                value={address2}
                onChange={(event) => setAddress2(event.target.value)}
                id="address2"
              />
            </div>
          </div>

          <div className="form-edit-item">
            <div className="form-edit-title">
              <BoxRequired text={t('textPhone')} />
            </div>
            <div className="form-edit-input flex-column">
              <div className="from-input-edit-item form-edit-input-max-width">
                <input
                  className="form-control input-group"
                  placeholder="半角数字"
                  maxLength="11"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value.replace(/\D/g, ''))}
                  id="phone"
                />
              </div>
              {validationMsg.phone && <div className="txt_error">{validationMsg.phone}</div>}
            </div>
          </div>
          <div className="form-edit-notify">
            <div className="text-note">{t('alertPhone')}</div>
          </div>

          <div className="form-edit-item">
            <div className="form-edit-title">
              <BoxRequired text={t('textEmailDelivery')} />
            </div>
            <div className="form-edit-input flex-column">
              <div className="from-input-edit-item form-edit-input-max-width">
                <input
                  className="form-control input-group"
                  placeholder="test@example.com"
                  maxLength="255"
                  value={emailDelivery}
                  onChange={(event) =>
                    setEmailDelivery(event.target.value.replace(regexFullWidth, ''))
                  }
                  id="emailDelivery"
                />
              </div>
              {validationMsg.emailDelivery && (
                <div className="txt_error">{validationMsg.emailDelivery}</div>
              )}
            </div>
          </div>

          <div className="form-edit-item has-note">
            <div className="form-edit-title">
              <BoxRequired text={t('textEmailDeliveryConfirm')} />
              <div className="title-note">（確認用）</div>
            </div>
            <div className="form-edit-input">
              <div className="from-input-edit-item form-edit-input-max-width">
                <input
                  className="form-control input-group"
                  placeholder="test@example.com"
                  maxLength="255"
                  value={emailDeliveryConfirm}
                  onChange={(event) =>
                    setEmailDeliveryConfirm(event.target.value.replace(regexFullWidth, ''))
                  }
                  id="emailDeliveryConfirm"
                />
                {validationMsg.emailDeliveryConfirm && (
                  <div className="txt_error">{validationMsg.emailDeliveryConfirm}</div>
                )}
              </div>
            </div>
          </div>

          <div className="form-btn-submit">
            <div className="d-flex align-items-center">
              <input
                className="checkbox-input"
                type="checkbox"
                value={checkedPolicy}
                onChange={() => setCheckedPolicy(!checkedPolicy)}
              />
              <label className="title-checkbox">
                お届け先登録にあたり、
                <span
                  className="link-policy"
                  onClick={() => window.open('https://www.besteight.co.jp/company/detail/?id=119')}
                >
                  プライバシーポリシーと個人情報の取り扱い
                </span>
                に同意します。
              </label>
            </div>
            {!hideCheckbox ? (
              <>
                <div className="d-flex align-items-center mt-3">
                  <input
                    className="checkbox-input"
                    type="checkbox"
                    value={checked}
                    onChange={() => {
                      checked === 0 ? setChecked(1) : setChecked(0);
                    }}
                  />
                  <label className="title-checkbox">お届け先を保存する</label>
                </div>
              </>
            ) : (
              ''
            )}

            <div className="btnEditEmail">
              <TButton
                className="primary-yellow"
                type="submit"
                title={id || orderId ? 'お届け先を変更する' : t('buttonEditEmail')}
                disabled={!checkedPolicy}
              />
            </div>
          </div>
        </form>
      </div>
      <ScrollButton />
    </div>
  );
}
