// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TButton from '../../common/button';
import SNSManage from './SNSManage';

function MyPageSP(props) {
  const { data } = props;
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      {data && (
        <>
          <div className="my-page-info">
            <p className="title">{t('titleAddressSetting')}</p>
            <div className="my-addressSetting-sp">
              {data.first_name && data.last_name && (
                <div className="name-user">
                  {t('userName').replace('param', data.last_name + data.first_name)}
                </div>
              )}

              {data.postal_code ? (
                <div className="my-page-info-profile">
                  <div className="address-my-page">
                    <p className="mb-0">{t('postalCode').replace('param', data.postal_code)}</p>
                    <p className="mb-0">
                      {data.province}
                      {data.district}
                      {data.address1}
                    </p>
                    <p className="mb-0">{data.address2}</p>
                  </div>
                  <p className="mb-0">{t('telFormat').replace('param', data.phone)}</p>
                  <p className="mb-0 word-email">
                    {t('mailFormat').replace('param', data.email_delivery_address)}
                  </p>
                </div>
              ) : (
                <p className="text-setting-address">{t('textSettingAddress')}</p>
              )}

              <div className="btn-page-info-address">
                <TButton
                  title={t('textBtnEditAddress')}
                  className="primary"
                  onClick={() =>
                    history.push({
                      pathname: '/edit-address',
                      state: { hideCheckbox: true },
                    })
                  }
                />
              </div>
              <p className="description-address mb-0">
                {t('descriptionSettingAddress').replace('param', '')}
              </p>
              <p className="description-note-address">{t('descriptionNoteSettingAddress')}</p>
            </div>

            <p className="title">{t('titlePoint')}</p>
            <div className="my-point-sp">
              <p className="text-total-point mb-0">
                {t('textTotalPoint').replace('param', data.total_point.toLocaleString('ja-JP'))}
              </p>
              {data?.expired_date && (
                <p className="text-total-point mb-0">
                  {t('expiredDatePoint').replace('param', data?.expired_date)}
                </p>
              )}
              <div className="btn-page-point-history-sp">
                <TButton
                  title={t('textBtnPointHistory')}
                  className="primary"
                  onClick={() => history.push('/point-history')}
                />
              </div>
            </div>

            <p className="title">ログイン情報</p>
            <div className="my-page-sns">
              <p className="my-page-sns-info-sp">MAIL:{data?.email}</p>
              <div className="btn-page-sns-info-sp">
                <TButton
                  title="メールアドレスを変更する"
                  className="primary"
                  onClick={() => history.push('/change-email')}
                />
              </div>
              {data?.have_password === 1 ? (
                <p className="my-page-sns-info">PASSWORD:**********</p>
              ) : (
                <p className="my-page-sns-info">未設定</p>
              )}

              <div className="btn-page-sns-info-sp">
                <TButton
                  title="パスワードを変更する"
                  className="primary"
                  onClick={() => history.push({ pathname: '/change-password', state: data?.email })}
                />
              </div>
            </div>

            <p className="title">外部サービス連携</p>
            <div className="my-page-sns-social">
              <SNSManage name="Googleでログイン" status={data?.linked_google} />
              <SNSManage name="Twitterでログイン" status={data?.linked_twitter} />
              <SNSManage name="Facebookでログイン" status={data?.linked_facebook} />
              <div className="btn-page-sns-info-sp btn-manager-sns-sp">
                <TButton
                  title="連携情報変更"
                  className="primary"
                  onClick={() => history.push('/sns-management')}
                />
              </div>
            </div>
          </div>

          <div className="text-center">
            <div className="btn-back-top-my-page btn-page-sns-info-sp">
              <TButton
                title={t('textBtnBackTop')}
                className="primary-yellow"
                onClick={() => history.push('/')}
              />
            </div>
            <div className="btn-my-page-policy" onClick={() => history.push('/delete-account')}>
              退会は<span>こちら</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MyPageSP;
