// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PaymentMethod from '../../components/pages/purchaseLottery/PaymentMethod';
import PriceArea from '../../components/pages/purchaseLottery/PriceArea';
import SelectQuantity from '../../components/pages/purchaseLottery/SelectQuantity';
import SettingAddress from '../../components/pages/purchaseLottery/SettingAddress';
import { useTranslation } from 'react-i18next';
import { LotteryApis } from '../../services/lotteryDetail';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import './select.scss';
import './radio.scss';
import {
  BUY_LOTS,
  FLAG_FREE_SHIP,
  listsErrorNotShowToast,
  NOT_OPEN_FOR_SALE,
  OFF_SALE,
  RETAIL,
} from '../../constants';
import { UserInfoApis } from '../../services/userInfo';
import { countPrice } from '../../utils/countPrice';
import useMessage from '../../components/common/toast/UseMessage';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import TButton from '../../components/common/button';
import ConfirmPurchase from '../../components/pages/purchaseLottery/ConfirmPurchase';
import TopHeader from '../../components/common/topHeader';
import queryString from 'query-string';
import ModalDiscardGuideSP from '../../components/common/modalDiscardGuide/ModalDiscardGuideSP';

function PurchaseLottery(props) {
  const history = useHistory();
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;
  const search = queryString.parse(history?.location?.search);

  const { t } = useTranslation();
  const { id } = useParams();
  const { openMessageError } = useMessage();

  const dispatch = useAppDispatch();
  const [data, setData] = useState();
  const [dataInfo, setDataInfo] = useState();

  const getPurchaseDetail = async () => {
    dispatch(showLoading());
    try {
      const res = await LotteryApis.getDetail(id);
      getUserInfo();
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getLotteryTrialDetail = async () => {
    dispatch(showLoading());
    try {
      const res = await LotteryApis.getDetailTrial();
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const formData = {
    info: 'purchase',
    lottery_event_id: id,
  };

  const formDataHasAddressId = {
    info: 'purchase',
    lottery_event_id: id,
    address_id: Number(search?.address_id),
  };

  const getUserInfo = async () => {
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(search?.address_id ? formDataHasAddressId : formData);
      const { data } = res;
      setDataInfo(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (pathSite === pathLotteryTrial) {
      getLotteryTrialDetail();
    } else {
      getPurchaseDetail();
    }
  }, []);

  const [purchaseMethod, setPurchaseMethod] = useState(
    Number(search?.adult_purchase) ? Number(search?.adult_purchase) : RETAIL,
  );
  const [numberOfLottery, setNumberOfLottery] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState(
    Number(search?.payment_method) ? Number(search?.payment_method) : 1,
  );
  const getPurchaseMethod = (method) => {
    setPurchaseMethod(method);
  };
  const getNumberOfLottery = (number) => {
    setNumberOfLottery(number);
  };
  const getPaymentMethod = (method) => {
    setPaymentMethod(method);
  };

  const lotteryEvent = data?.lottery_event;
  const flagPoint = lotteryEvent?.use_point_flag;
  const flagSale = lotteryEvent?.sale_status;

  useEffect(() => {
    if (
      (flagSale === NOT_OPEN_FOR_SALE || flagSale === OFF_SALE) &&
      pathSite !== pathLotteryTrial
    ) {
      history.push('/error/404');
    }
  }, [flagSale]);

  const selectNumber =
    purchaseMethod === RETAIL ? numberOfLottery : lotteryEvent?.lottery_per_package;

  const priceIncludedTax = countPrice(lotteryEvent?.price_included_tax, selectNumber);

  const remainingFreeShip =
    (Number(dataInfo?.total_buy) + selectNumber) % lotteryEvent?.ship_quantity === 0
      ? 0
      : lotteryEvent?.ship_quantity -
        ((Number(dataInfo?.total_buy) + selectNumber) % lotteryEvent?.ship_quantity);

  const calculateShippingFee =
    (Math.ceil((Number(dataInfo?.total_buy) + selectNumber) / lotteryEvent?.ship_quantity) -
      Math.ceil(Number(dataInfo?.total_buy) / lotteryEvent?.ship_quantity)) *
    lotteryEvent?.value_shipping_fee;

  const feeShip =
    (lotteryEvent?.free_ship_package_flag === FLAG_FREE_SHIP && purchaseMethod === BUY_LOTS) ||
    lotteryEvent?.all_digital
      ? 0
      : calculateShippingFee;

  const totalTax = 0;
  const totalShippingTax = Math.floor((feeShip * lotteryEvent?.tax_rate) / 100);
  const feeShipIncludedTax = feeShip + totalShippingTax;
  const totalPriceByPointMethod = Math.floor(feeShip * (lotteryEvent?.tax_rate / 100 + 1));

  const [priceByPoint, setPriceByPoint] = useState(
    Number(search?.point) ? Number(search?.point) : 0,
  );
  const [isCheckPoint, setIsCheckPoint] = useState(true);
  const getPriceByPoint = (point) => {
    setPriceByPoint(Number(point));
  };
  const getCheckPoint = (check) => {
    setIsCheckPoint(check);
  };

  //calculate point
  const calculatePricePoint = lotteryEvent?.price_by_point_included_tax * selectNumber;
  const totalShippingTaxHasPoint =  Math.floor(
      (feeShip * lotteryEvent?.tax_rate) / 100,
  );
  const totalPriceHasPoint = calculatePricePoint + feeShip + totalShippingTaxHasPoint - priceByPoint;
  const totalPrice = priceIncludedTax + feeShip + totalShippingTax;
  const actualTotalPoint =
    flagPoint === 1 && priceByPoint !== 0 ? totalPriceHasPoint : totalPrice;

  const dataPrice = {
    selectNumber,
    priceIncludedTax,
    feeShipIncludedTax,
    remainingFreeShip,
    totalTax,
    totalPrice,
    priceByPoint,
    totalPriceByPointMethod,
    paymentMethod,
    numberOfLottery,
    purchaseMethod,
    actualTotalPoint,
  };

  const dataPriceTrial = {
    selectNumber,
    priceIncludedTax: 0,
    feeShipIncludedTax: 0,
    remainingFreeShip: 0,
    totalTax: 0,
    totalPrice: 0,
    priceByPoint: 0,
    totalPriceByPointMethod: 0,
    actualTotalPoint: 0,
    paymentMethod,
    numberOfLottery,
    purchaseMethod,
  };

  const formDataPurchase = {
    lottery_event_id: id,
    quantity: selectNumber,
    adult_purchase: purchaseMethod,
    shipping_fee: feeShip,
    payment_method_id: paymentMethod,
    number_of_purchase: dataInfo?.number_of_purchase,
    address_id: Number(search?.address_id),
    number_point_used: Number(priceByPoint),
  };

  const [isShowModal, setShowModal] = useState(false);
  const [isCheckBox, setCheckBox] = useState(false);
  const isShowButtonConfirm = isCheckBox && dataInfo?.postal_code && isCheckPoint;

  const calculatorShipQuantity =
    lotteryEvent?.lottery_per_package <= 1 ? 1 : lotteryEvent?.lottery_per_package - 1;
  const maxShipQuantity = lotteryEvent?.lottery_per_package ? calculatorShipQuantity : 100;

  useEffect(() => {
    if (
      search?.quantity &&
      (Number(search?.quantity) < 1 || Number(search?.quantity) > maxShipQuantity)
    ) {
      history.push('/error/404');
    }
    if (
      search?.adult_purchase &&
      Number(search?.adult_purchase) !== RETAIL &&
      Number(search?.adult_purchase) !== BUY_LOTS
    ) {
      history.push('/error/404');
    }
  }, [maxShipQuantity]);

  const [isShowDiscardGuide, setShowDiscardGuide] = useState(false);

  return (
    <>
      <TopHeader title="購入手続き" />
      {data && (
        <div className="purchase-lottery">
          {data && (
            <>
              <div className="wrap-purchase-image-sp">
                <img
                  src={data?.lottery_event?.image?.thumbnail_url}
                  alt="img"
                  className="purchase-image-sp"
                />
              </div>
              <div className="title">
                <strong>{t('titlePurchaseDetail')}</strong> <span>{data?.lottery_event?.name}</span>
              </div>
            </>
          )}

          <div className="purchase-lottery-content">
            <div className="purchase-setup-info">
              {data && (
                <SelectQuantity
                  data={data}
                  getPurchaseMethod={getPurchaseMethod}
                  getNumberOfLottery={getNumberOfLottery}
                  numberOfLottery={numberOfLottery}
                  search={search}
                />
              )}

              <div className="purchase-price-info-sp">
                <div className="title-inbox-sp">{t('titlePriceArea')}</div>
                <PriceArea
                  data={data}
                  purchaseMethod={purchaseMethod}
                  dataInfo={dataInfo}
                  paymentMethod={paymentMethod}
                  dataPrice={pathLotteryTrial === pathSite ? dataPriceTrial : dataPrice}
                  formData={formDataPurchase}
                />
              </div>

              {data && (
                <PaymentMethod
                  data={data}
                  dataInfo={dataInfo}
                  numberOfLottery={numberOfLottery}
                  paymentMethod={paymentMethod}
                  purchaseMethod={purchaseMethod}
                  getPaymentMethod={getPaymentMethod}
                />
              )}
              {pathLotteryTrial === pathSite && data && (
                <>
                  <div className="title-inbox-sp">{t('titleAddressSetting')}</div>
                  <div className="my-setting-address my-box-purchase">
                    <div className="title">
                      <strong className="title-inbox">{t('titleAddressSetting')}</strong>
                    </div>
                    <div style={{ color: 'rgb(255, 0, 0)', whiteSpace: 'pre-wrap ' }}>
                      {`お試しくじのため、\nお届け先の住所の登録は不要です。`}
                    </div>
                  </div>
                </>
              )}
              {dataInfo && (
                <>
                  <div className="title-inbox-sp">{t('titleAddressSetting')}</div>
                  <SettingAddress
                    dataInfo={dataInfo}
                    data={formDataPurchase}
                    lotteryEvent={lotteryEvent}
                    getPriceByPoint={getPriceByPoint}
                    getCheckPoint={getCheckPoint}
                  />
                </>
              )}
            </div>

            <div className="purchase-price-info">
              <PriceArea
                data={data}
                purchaseMethod={purchaseMethod}
                dataInfo={dataInfo}
                paymentMethod={paymentMethod}
                dataPrice={pathLotteryTrial === pathSite ? dataPriceTrial : dataPrice}
                formData={formDataPurchase}
              />
            </div>
            <div className="confirm-sp">
              {pathLotteryTrial === pathSite ? (
                <div
                  style={{
                    fontSize: '14px',
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    color: '#464646',
                  }}
                >
                  {`お試しクジは無料で体験できます。\nお試しのため、商品のお届けはありません。`}
                </div>
              ) : (
                <>
                  <input type="checkbox" onClick={() => setCheckBox(!isCheckBox)} />
                  <div className="text">
                    購入にあたり、
                    <span className="cursor-pointer link" onClick={() => history.push('/terms')}>
                      利用規約
                    </span>
                    および
                    <span className="cursor-pointer link" onClick={() => setShowDiscardGuide(true)}>
                      破棄について
                    </span>
                    に同意します。
                  </div>
                </>
              )}
            </div>
            <div className="text-center btn-purchase-sp">
              {pathSite === pathLotteryTrial ? (
                <TButton
                  title={t('textBtnPurchaseLottery')}
                  className="my-btn-custom-sp primary-yellow"
                  onClick={() => setShowModal(true)}
                />
              ) : (
                <TButton
                  title={t('textBtnPurchaseLottery')}
                  className="my-btn-custom-sp primary-yellow"
                  disabled={!isShowButtonConfirm}
                  onClick={() => setShowModal(true)}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <ScrollButton />
      <ConfirmPurchase
        show={isShowModal}
        onHide={() => setShowModal(false)}
        data={formDataPurchase}
        title={lotteryEvent?.name}
        quantity={selectNumber}
        price={dataPrice}
        flag={data?.oc_prize_quantity_flag}
      />

      <ModalDiscardGuideSP
        isShowDiscardGuide={isShowDiscardGuide}
        setShowDiscardGuide={setShowDiscardGuide}
      />
    </>
  );
}

export default PurchaseLottery;
