// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TButton from '../../common/button';
import { MY_PAGE } from '../../../../assets/imgs';
import SNSManage from './SNSManage';

function MyPagePC(props) {
  const { data } = props;
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      {data && (
        <>
          <div className="my-page-info">
            <div className="title">{t('titleInfoCreateMember')}</div>
            <Table bordered>
              <tbody>
                <tr>
                  <th>{t('titleAddressSetting')}</th>
                  <td>
                    {data.first_name && data.last_name && (
                      <div className="name-user">
                        {t('userName').replace('param', data.last_name + data.first_name)}
                      </div>
                    )}

                    {data.postal_code ? (
                      <div className="my-page-info-profile">
                        <p className="mb-0">{t('postalCode').replace('param', data.postal_code)}</p>
                        <p className="mb-0">
                          {data.province}
                          {data.district}
                          {data.address1}
                        </p>
                        <p className="mb-0">{data.address2}</p>
                        <p className="mb-0">{t('telFormat').replace('param', data.phone)}</p>
                        <p className="mb-0">
                          {t('mailFormat').replace('param', data.email_delivery_address)}
                        </p>
                      </div>
                    ) : (
                      <p className="text-setting-address">{t('textSettingAddress')}</p>
                    )}

                    <div className="btn-page-info-address">
                      <TButton
                        title={t('textBtnEditAddress')}
                        className="primary"
                        onClick={() =>
                          history.push({
                            pathname: '/edit-address',
                            state: { hideCheckbox: true },
                          })
                        }
                      />
                    </div>
                    <p className="description-address mb-0">
                      {t('descriptionSettingAddress').replace('param', '')}
                    </p>
                    <p className="description-note-address">{t('descriptionNoteSettingAddress')}</p>
                  </td>
                </tr>
                <tr>
                  <th>{t('titlePoint')}</th>
                  <td className="text-total-point">
                    <p className="mb-0">
                      {t('textTotalPoint').replace(
                        'param',
                        data?.total_point?.toLocaleString('ja-JP'),
                      )}
                    </p>
                    <p className="mb-0">
                      {data?.expired_date &&
                        t('expiredDatePoint').replace('param', data?.expired_date)}
                    </p>
                    <div className="btn-page-point-history">
                      <TButton
                        title={t('textBtnPointHistory')}
                        className="primary"
                        onClick={() => history.push('/point-history')}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="my-page-sns">
            <div className="title">ログイン情報</div>
            <Table bordered>
              <tbody>
                <tr>
                  <th>ログイン情報</th>
                  <td>
                    <p className="my-page-sns-info">
                      {t('mailFormat').replace('param', data?.email)}
                    </p>

                    <div className="btn-page-sns-info">
                      <TButton
                        title="メールアドレスを変更する"
                        className="primary"
                        onClick={() => history.push('/change-email')}
                      />
                    </div>
                    {data?.have_password === 1 ? (
                      <p className="my-page-sns-info">PASSWORD : **********</p>
                    ) : (
                      <p className="my-page-sns-info">未設定</p>
                    )}

                    <div className="btn-page-sns-info">
                      <TButton
                        title="パスワードを変更する"
                        className="primary"
                        onClick={() =>
                          history.push({ pathname: '/change-password', state: data?.email })
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>外部サービス連携</th>
                  <td>
                    <SNSManage
                      image={MY_PAGE.iconGoogle}
                      name="Googleでログイン"
                      status={data?.linked_google}
                      isPC={true}
                    />
                    <SNSManage
                      image={MY_PAGE.iconTwitter}
                      name="Twitterでログイン"
                      status={data?.linked_twitter}
                      isPC={true}
                    />
                    <SNSManage
                      image={MY_PAGE.iconFacebook}
                      name="Facebookでログイン"
                      status={data?.linked_facebook}
                      isPC={true}
                    />
                    <div className="btn-page-sns-info btn-manager-sns">
                      <TButton
                        title="連携情報変更"
                        className="primary"
                        onClick={() => history.push('/sns-management')}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="text-center">
            <div className="btn-back-top-my-page">
              <TButton
                title={t('textBtnBackTop')}
                className="primary-yellow"
                onClick={() => history.push('/')}
              />
            </div>
            <div className="btn-my-page-policy" onClick={() => history.push('/delete-account')}>
              退会は<span>こちら</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MyPagePC;
