// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../../../common/button';
import { useHistory, useParams } from 'react-router-dom';
export default function DeliveryAddress(props) {
  const { dataOrders } = props;
  const addressDelivery = dataOrders?.address_delivery;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const moveToEditAddress = () => {
    history.push(`/order/history/${id}/edit-address`);
  };

  return (
    <div>
      <div className="border-top-sp " />
      <div className="title-detail-sp mt80">{t('titleDeliveryAddress')}</div>
      <div className="border-sp" />
      <div className="information-purchase-history">
        {addressDelivery ? (
          <div>
            <div className="address-name">
              <span className="info-name">
                {addressDelivery?.last_name} {addressDelivery?.first_name} 様
              </span>
            </div>
            <div className="address-delivery-sp">
              <span>〒{addressDelivery?.postal_code}</span>
              <span>
                {addressDelivery?.province}
                {addressDelivery?.district}
                {addressDelivery?.address1}
              </span>
              <span className="address-delivery">{addressDelivery?.address2}</span>
              <span>TEL : {addressDelivery?.phone}</span>
              <span>EMAIL : {addressDelivery?.email_delivery_address}</span>
            </div>
            {dataOrders?.date_change_address ? (
              <span>
                <div className="status-shipping-sp">{t('textStatusEditAddress')}</div>
                <span>{dataOrders?.date_change_address}</span>
              </span>
            ) : (
              <span />
            )}
          </div>
        ) : (
          <p>{t('textAlertDeliveryAddress')}</p>
        )}

        <div>
          <p>{t('textEstimatedDeliveryDate')}</p>
          <span>{dataOrders?.estimate_delivery_date}</span>
        </div>
        <div>
          <p>{t('textDeliveryStatus')}</p>
          <span>{dataOrders?.shipping_status_label}</span>
        </div>
        {dataOrders?.date_change_address && dataOrders?.can_change_address ? (
          <div className="btn-edit-address-sp">
            <TButton
              title={t('textBtnEditAddress')}
              className="primary"
              onClick={moveToEditAddress}
            />
          </div>
        ) : (
          <div />
        )}
        <div className="border-sp mt40" />
      </div>
    </div>
  );
}
