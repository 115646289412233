// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

export const BREAK_POINT = 768;
export const ON_CHALLENGE = 'OC';
export const PERIOD_CURRENT = 1;
export const PERIOD_EXPIRED = 0;
export const OUT_OF_STOCK = 0;
export const NOT_OPEN_FOR_SALE = 0;
export const ON_SALE = 1;
export const OFF_SALE = 2;
export const READY_TO_SALE = 0;
export const USE_ADULT_PURCHASE = 1;
export const RETAIL = 0;
export const BUY_LOTS = 1;
export const FREE_SHIP_PRICE = 0;
export const FLAG_FREE_SHIP = 1;
export const USE_CASH = 1;
export const STATUS_DIGITAL_PRIZE = 100;
export const URL_PREFECTURES = 'https://yubinbango.github.io/yubinbango-data/data';
export const PREFECTURES = [
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 13, label: '東京都' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄県' },
];

export const RANK_A = 'A';
export const URL_NOT_REDIRECT = [
  '/login',
  '/register',
  '/reset-password',
  '/forgot-password',
  '/delete-account',
  '/error/404',
  '/error/403',
  '/error/504',
  '/error/503',
  '/verify/invalid',
  '/verify',
  '/twitter',
];

export const listsErrorNotShowToast = [503];
export const regexFullWidth =
  /[０-９ａ-ｚＡ-Ｚァ-ン\，\．\：\；\！\？\＂\＇\｀\＾\～\￣\＿\＆\＠\＃\％\＋\－\＊\＝\＜\＞\（\）\［\］\｛\｝\｟\｠\｜\￤\／\＼\￢\＄\￡\￠\￦\￥]+/g;
export const STATUS_URL = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const QUANTITY_PRIZE = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const LANDING_PAGE = {
  urlLandingPage1: '/lp1',
  isLandingPage1: 1,
  urlLandingPage2: '/lp2',
  isLandingPage2: 11,
};

export const LIST_RANK = [
  {
    rankName: 'A',
    customName: null,
    value: 0,
  },
  {
    rankName: 'B',
    customName: null,
    value: 0,
  },
  {
    rankName: 'C',
    customName: null,
    value: 0,
  },
  {
    rankName: 'D',
    customName: null,
    value: 0,
  },
  {
    rankName: 'E',
    customName: null,
    value: 0,
  },
  {
    rankName: 'F',
    customName: null,
    value: 0,
  },
  {
    rankName: 'G',
    customName: null,
    value: 0,
  },
  {
    rankName: 'H',
    customName: null,
    value: 0,
  },
  {
    rankName: 'I',
    customName: null,
    value: 0,
  },
  {
    rankName: 'J',
    customName: null,
    value: 0,
  },
];
