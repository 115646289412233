// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import TButton from '../../../common/button';
import { useHistory, useParams } from 'react-router-dom';

export default function DeliveryAddress(props) {
  const { dataOrders } = props;
  const addressDelivery = dataOrders?.address_delivery;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const moveToEditAddress = () => {
    history.push(`/order/history/${id}/edit-address`);
  };

  return (
    <div className="delivery-address">
      <p className="title-detail">{t('titleDeliveryAddress')}</p>
      <Table bordered className="mb40">
        <tbody>
          <tr>
            <th>{t('textInformationAddress')}</th>
            <td className="information-address">
              {addressDelivery ? (
                <div className="info-basic">
                  <div className="info-name">
                    {addressDelivery?.last_name} {addressDelivery?.first_name} 様
                  </div>
                  <div className="information-detail">
                    <div>〒{addressDelivery?.postal_code}</div>
                    <div>
                      {addressDelivery?.province}
                      {addressDelivery?.district}
                      {addressDelivery?.address1}
                    </div>
                    <div>{addressDelivery?.address2}</div>
                    <div>TEL : {addressDelivery?.phone}</div>
                    <div>MAIL : {addressDelivery?.email_delivery_address}</div>
                  </div>
                </div>
              ) : (
                <div>{t('textAlertDeliveryAddress')}</div>
              )}

              {dataOrders?.date_change_address ? (
                <div className="time-edit-shipping">
                  <div className="status-shipping">{t('textStatusEditAddress')}</div>
                  <div>{dataOrders?.date_change_address}</div>
                </div>
              ) : (
                <div />
              )}
              {dataOrders?.date_change_address && dataOrders?.can_change_address ? (
                <TButton
                  title={t('textBtnEditAddress')}
                  className="primary btn-edit-address"
                  onClick={moveToEditAddress}
                />
              ) : (
                <div />
              )}
            </td>
          </tr>
          <tr>
            <th>{t('textEstimatedDeliveryDate')}</th>
            <td>{dataOrders?.estimate_delivery_date}</td>
          </tr>
          <tr>
            <th>{t('textDeliveryStatus')}</th>
            <td>{dataOrders?.shipping_status_label}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
