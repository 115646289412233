// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import TopHeader from '../../components/common/topHeader';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import useMessage from '../../components/common/toast/UseMessage';
import { BREAK_POINT, listsErrorNotShowToast } from '../../constants';
import MediaQuery from 'react-responsive';
import PurchaseHistoryDetailPC from './PurchaseHistoryDetailPC';
import PurchaseHistoryDetailSP from './PurchaseHistoryDetailSP';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';
import { PurchaseHistoryApis } from '../../services/purchaseHistory';
import TButton from '../../components/common/button';
export default function PurchaseHistoryDetail() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { openMessageError } = useMessage();
  const [dataPurchaseHistory, setDataPurchaseHistory] = useState();
  const history = useHistory();

  const getPurchaseHistoryDetail = async () => {
    dispatch(showLoading());
    try {
      const res = await PurchaseHistoryApis.getDataDetail({ order_id: id });
      const { data } = res;
      setDataPurchaseHistory(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getPurchaseHistoryDetail();
  }, []);

  return (
    <>
      <TopHeader title={t('titlePurchaseHistoryDetail')} />
      <div className="main-content purchase-history-detail">
        <MediaQuery minWidth={BREAK_POINT + 1}>
          <PurchaseHistoryDetailPC data={dataPurchaseHistory} />
        </MediaQuery>
        <MediaQuery maxWidth={BREAK_POINT}>
          <PurchaseHistoryDetailSP data={dataPurchaseHistory} />
        </MediaQuery>
        <div className="action_btn">
          <TButton
            title="購入履歴へ"
            className="primary-yellow btn_back_order_history"
            onClick={() => history.push('/order/history')}
          />
          <TButton
            title="Mｙコレクションを見る"
            className="primary-yellow btn_back_my_collection"
            onClick={() => history.push('/my-collection')}
          />
        </div>
      </div>
    </>
  );
}
