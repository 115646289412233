// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BUY_LOTS, FLAG_FREE_SHIP } from '../../../constants';
import PriceItemArea from './PriceItemArea';

function PriceShow(props) {
  const { data, dataPrice } = props;
  const lotteryEvent = data?.lottery_event;
  const flag = lotteryEvent?.use_point_flag;
  const { t } = useTranslation();

  const history = useHistory();
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;

  return (
    <>
      <div className="price-show-content">
        <div className="price-info">
          <div className="title">
            <strong>{t('titleUnitPrice')}(税込)</strong>
          </div>
          <div className="d-flex align-items-center flex-wrap show-value">
            <div>
              {pathLotteryTrial === pathSite ? 0 : lotteryEvent.price_included_tax.toLocaleString('ja-JP')}円
            </div>{' '}
            <div>× {dataPrice.selectNumber}枚</div>
          </div>
        </div>

        <PriceItemArea
          title={t('titleSubTotal')}
          value={t('valuePrice').replace('param', dataPrice.priceIncludedTax.toLocaleString('ja-JP'))}
        />

        <PriceItemArea
          title={t('textShippingFee')}
          value={t('valuePrice').replace('param', dataPrice.feeShipIncludedTax.toLocaleString('ja-JP'))}
        />
        {(dataPrice.purchaseMethod === BUY_LOTS &&
          lotteryEvent.free_ship_package_flag === FLAG_FREE_SHIP) ||
        pathLotteryTrial === pathSite ? (
          ''
        ) : (
          <div className="text-purchase-more">
            {t('textLotteryPurchaseMore')
              .replace(
                'B', (
                    dataPrice.feeShipIncludedTax
                ).toLocaleString('ja-JP'),
              )
              .replace('A', dataPrice.remainingFreeShip)}
          </div>
        )}

      </div>
      <div className="line" />

      <div className="price-show-content price-show-content-bottom">
        <PriceItemArea
          title={t('textTotalPrice')}
          value={t('valuePrice').replace('param', dataPrice.totalPrice.toLocaleString('ja-JP'))}
        />

        <PriceItemArea
          title={t('textUsePoint')}
          value={t('valuePoint').replace(
            'param',
            flag === 1 ? dataPrice.priceByPoint.toLocaleString('ja-JP') : 0,
          )}
        />

        <PriceItemArea
          title={t('textPaymentAmount')}
          value={t('valuePrice').replace(
            'param',
            dataPrice.actualTotalPoint.toLocaleString('ja-JP'),
          )}
          className="actual-fee-price"
        />
      </div>
    </>
  );
}

export default PriceShow;
