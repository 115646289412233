// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import formatNumberBuyTogether from './FormatNumberBuyTogether';

export default function RenderFooterBuyTogether(listRank) {
  return (
    <div>
      {listRank.listRank.length > 0 &&
        listRank.listRank.map((rank, index) => (
          <div style={{ display: 'flex' }} key={index} className="scratch-result-item">
            <div className="scratch-result-item-name">
              {rank.customName ? rank.customName : rank.rankName + '賞'}
            </div>
            <div className="result_quantity">{formatNumberBuyTogether(rank.value, true)}</div>
          </div>
        ))}
    </div>
  );
}
