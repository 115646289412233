// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import BoxRequired from '../../components/common/boxRequired';
import { useAppDispatch } from '../../store';
import { AuthApis } from '../../services/auth';
import { validateEmail } from '../../validations/validateEmail';
import TButton from '../../components/common/button';
import ConfirmSuccess from '../../components/pages/forgotPassword/ConfirmSuccess';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import './styles.scss';
import { listsErrorNotShowToast } from '../../constants';

export default function ForgotPassword() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const emailRef = useRef();
  const [email, setEmail] = useState('');
  const [validationMsg, setValidationMsg] = useState('');
  const [isForgotSuccess, setForgotSuccess] = useState(false);

  const { openMessageError } = useMessage();

  const validateAll = () => {
    setValidationMsg(validateEmail(email.trim()));
    if (validateEmail(email.trim()) !== '') return false;
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) {
      emailRef.current.focus();
      return;
    }
    dispatch(showLoading());
    try {
      await AuthApis.forgotPassword({ email });
      setForgotSuccess(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <>
      {isForgotSuccess ? (
        <ConfirmSuccess />
      ) : (
        <>
          <TopHeader title={t('textForgotPassword')} />
          <div className="main-content">
            <form
              className="forgot-password"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form-forgot-password">
                <div className="title-forgot-password">{t('titleForgotPassword')}</div>

                <div>
                  <BoxRequired text={t('txtEmail')} />
                </div>

                <div>
                  <div className="input-group">
                    <input
                      ref={emailRef}
                      type="text"
                      autoFocus
                      className="form-control border-input"
                      placeholder="test@example.com"
                      maxLength="255"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  {validationMsg && <p className="txt_error">{validationMsg}</p>}
                </div>
                <div className="note-forgot">{t('noteForgot')}</div>
              </div>

              <div className="text-center btn-forgot-password">
                <TButton
                  title={t('textBtnSubmitForgot')}
                  className="btn primary-yellow"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}
