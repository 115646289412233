// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import MediaQuery from 'react-responsive';
import React from 'react';
import { BREAK_POINT } from '../../../constants';
const formatNumberBuyTogether = (number, isDE = false) => {
  if (number <= 9) {
    return (
      <>
        <MediaQuery minWidth={BREAK_POINT + 1}>
          <>
            {isDE ? (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          0
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            ) : (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="70"
                    viewBox="0 0 40 70"
                  >
                    <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                      <rect
                        id="Rectangle_4216"
                        data-name="Rectangle 4216"
                        width="40"
                        height="50"
                        transform="translate(713 1060)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(719 1103)"
                        fill="#fff"
                        fontSize="48"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          0
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="70"
                    viewBox="0 0 40 70"
                  >
                    <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                      <rect
                        id="Rectangle_4216"
                        data-name="Rectangle 4216"
                        width="40"
                        height="50"
                        transform="translate(713 1060)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(719 1103)"
                        fill="#fff"
                        fontSize="48"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            )}
          </>
        </MediaQuery>
        <MediaQuery maxWidth={BREAK_POINT}>
          <>
            {isDE ? (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="32"
                    viewBox="0 0 19 32"
                  >
                    <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                      <rect
                        id="Rectangle_4215"
                        data-name="Rectangle 4215"
                        width="19"
                        height="24"
                        transform="translate(-49 346.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-46 366.635)"
                        fill="#fff"
                        fontSize="22"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          0
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="32"
                    viewBox="0 0 19 32"
                  >
                    <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                      <rect
                        id="Rectangle_4215"
                        data-name="Rectangle 4215"
                        width="19"
                        height="24"
                        transform="translate(-49 346.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-46 366.635)"
                        fill="#fff"
                        fontSize="22"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            ) : (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          0
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            )}
          </>
        </MediaQuery>
      </>
    );
  }
  return (
    <>
      <MediaQuery minWidth={BREAK_POINT + 1}>
        <>
          {isDE ? (
            <>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="46" viewBox="0 0 24 46">
                  <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                    <rect
                      id="Rectangle_4207"
                      data-name="Rectangle 4207"
                      width="24"
                      height="32"
                      transform="translate(-109 307.635)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(-106 334.635)"
                      fill="#fff"
                      fontSize="32"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {Math.floor(number / 10)}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="46" viewBox="0 0 24 46">
                  <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                    <rect
                      id="Rectangle_4207"
                      data-name="Rectangle 4207"
                      width="24"
                      height="32"
                      transform="translate(-109 307.635)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(-106 334.635)"
                      fill="#fff"
                      fontSize="32"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {number % 10}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
            </>
          ) : (
            <>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="70" viewBox="0 0 40 70">
                  <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                    <rect
                      id="Rectangle_4216"
                      data-name="Rectangle 4216"
                      width="40"
                      height="50"
                      transform="translate(713 1060)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(719 1103)"
                      fill="#fff"
                      fontSize="48"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {Math.floor(number / 10)}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="70" viewBox="0 0 40 70">
                  <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                    <rect
                      id="Rectangle_4216"
                      data-name="Rectangle 4216"
                      width="40"
                      height="50"
                      transform="translate(713 1060)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(719 1103)"
                      fill="#fff"
                      fontSize="48"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {number % 10}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
            </>
          )}
        </>
      </MediaQuery>
      <MediaQuery maxWidth={BREAK_POINT}>
        <>
          {isDE ? (
            <>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="32" viewBox="0 0 19 32">
                  <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                    <rect
                      id="Rectangle_4215"
                      data-name="Rectangle 4215"
                      width="19"
                      height="24"
                      transform="translate(-49 346.635)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(-46 366.635)"
                      fill="#fff"
                      fontSize="22"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {Math.floor(number / 10)}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="32" viewBox="0 0 19 32">
                  <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                    <rect
                      id="Rectangle_4215"
                      data-name="Rectangle 4215"
                      width="19"
                      height="24"
                      transform="translate(-49 346.635)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(-46 366.635)"
                      fill="#fff"
                      fontSize="22"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {number % 10}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
            </>
          ) : (
            <>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="46" viewBox="0 0 24 46">
                  <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                    <rect
                      id="Rectangle_4207"
                      data-name="Rectangle 4207"
                      width="24"
                      height="32"
                      transform="translate(-109 307.635)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(-106 334.635)"
                      fill="#fff"
                      fontSize="32"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {Math.floor(number / 10)}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="46" viewBox="0 0 24 46">
                  <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                    <rect
                      id="Rectangle_4207"
                      data-name="Rectangle 4207"
                      width="24"
                      height="32"
                      transform="translate(-109 307.635)"
                      fill="#464646"
                    />
                    <text
                      id="_0"
                      data-name="0"
                      transform="translate(-106 334.635)"
                      fill="#fff"
                      fontSize="32"
                      fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0">
                        {number % 10}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </div>
            </>
          )}
        </>
      </MediaQuery>
    </>
  );
};

export default formatNumberBuyTogether;
