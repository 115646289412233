// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TButton from '../../common/button';
import ModalDiscardGuide from '../../common/modalDiscardGuide';
import ConfirmPurchase from './ConfirmPurchase';
import PriceShow from './PriceShow';

function PriceArea(props) {
  const { t } = useTranslation();
  const [isShowModal, setShowModal] = useState(false);
  const history = useHistory();
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;
  const { data, dataInfo, dataPrice, formData } = props;
  const lotteryEvent = data?.lottery_event;

  const [isCheckBox, setCheckBox] = useState(false);

  const isShowButtonConfirm = isCheckBox && dataInfo?.postal_code;

  const [isShowDiscardGuide, setShowDiscardGuide] = useState(false);

  return (
    <>
      {dataPrice && data && (
        <div className="my-price-area my-box-purchase">
          <div className="title">
            <strong className="title-inbox">{t('titlePriceArea')}</strong>
          </div>

          <div className="my-image-price">
            <img src={lotteryEvent?.image?.thumbnail_url} alt="img" />
          </div>

          <div className="my-before-tax-area">
            <PriceShow data={data} dataPrice={dataPrice} />
          </div>

          <div className="confirm">
            {pathLotteryTrial === pathSite ? (
              <div
                style={{
                  fontSize: '12px',
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-wrap',
                  color: '#464646',
                }}
              >
                {`お試しクジは無料で体験できます。\nお試しのため、商品のお届けはありません。`}
              </div>
            ) : (
              <>
                <input type="checkbox" onClick={() => setCheckBox(!isCheckBox)} />
                <div className="text">
                  購入にあたり、
                  <span className="cursor-pointer link" onClick={() => history.push('/terms')}>
                    利用規約
                  </span>
                  および
                  <span className="cursor-pointer link" onClick={() => setShowDiscardGuide(true)}>
                    破棄について
                  </span>
                  に同意します。
                </div>
              </>
            )}
          </div>
          <div className="text-center btn-purchase">
            {pathSite === pathLotteryTrial ? (
              <TButton
                title={t('textBtnPurchaseLottery')}
                className="my-btn-custom primary-yellow"
                onClick={() => setShowModal(true)}
              />
            ) : (
              <TButton
                title={t('textBtnPurchaseLottery')}
                className="my-btn-custom primary-yellow"
                disabled={!isShowButtonConfirm}
                onClick={() => setShowModal(true)}
              />
            )}
          </div>

          <ConfirmPurchase
            show={isShowModal}
            onHide={() => setShowModal(false)}
            data={formData}
            title={lotteryEvent.name}
            quantity={dataPrice?.selectNumber}
            price={dataPrice}
            flag={data?.oc_prize_quantity_flag}
          />
        </div>
      )}

      <ModalDiscardGuide
        isShowDiscardGuide={isShowDiscardGuide}
        setShowDiscardGuide={setShowDiscardGuide}
      />
    </>
  );
}

export default PriceArea;
