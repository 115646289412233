// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import { AuthApis } from '../../services/auth';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import {
  validateConfirmNewPassword,
  validateNewPassword,
} from '../../validations/validatePassword';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import '../forgotPassword/styles.scss';
import TButton from '../../components/common/button';
import { listsErrorNotShowToast } from '../../constants';
import ModalResetSuccess from './modalResetSuccess';

export default function ResetPassword() {
  const { openMessageError, openMessage } = useMessage();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setConfirmPassword] = useState('');
  const [validationMsg, setValidationMsg] = useState({});
  const search = queryString.parse(history.location.search);
  const [modalShow, setModalShow] = useState(false);

  const validateAll = () => {
    const error = {
      password: '',
      passwordConfirmation: '',
    };
    error.password = validateNewPassword(password);
    error.passwordConfirmation = validateConfirmNewPassword(password, passwordConfirmation);
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i++) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };
  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    const formData = {
      token: search.token,
      email: search.email,
      password: password,
      password_confirmation: passwordConfirmation,
    };
    try {
      const res = await AuthApis.resetPassword(formData);
      const { data } = res;
      const messageSuccess = data.message;
      openMessage({ message: `${messageSuccess}`, variant: 'success' });
      setModalShow(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const checkVerify = async () => {
    dispatch(showLoading());
    const formData = {
      token: search.token,
      email: search.email,
    };
    try {
      await AuthApis.resetPasswordVerify(formData);
    } catch (error) {
      if (Number(error?.response?.status) === 400) {
        history.push('/verify/invalid');
      }
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (search?.token && search?.email) {
      checkVerify();
    } else {
      history.push('/error/404');
    }
  }, []);

  return (
    <div>
      <TopHeader title={t('headerResetPassword')} />
      <form
        className="main-content forgot-password"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="form-forgot-password">
          <div className="title-forgot-password">{t('titleResetPassword')}</div>
          <div className="fw-bold title-reset-password ">{t('txtResetPassword')}</div>
          <div className="input-reset-password">
            <div className="input-group">
              <input
                maxLength="16"
                type="password"
                className="form-control border-input"
                placeholder="半角英数字8~16文字"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              ></input>
            </div>
            {validationMsg.password && <div className="txt_error">{validationMsg.password}</div>}
          </div>
          <div className="fw-bold title-reset-password ">{t('txtConfirmResetPassword')}</div>
          <div>
            <div className="input-group">
              <input
                maxLength="16"
                type="password"
                className="form-control border-input"
                placeholder="半角英数字8~16文字"
                value={passwordConfirmation}
                onChange={(event) => setConfirmPassword(event.target.value)}
              ></input>
            </div>
            {validationMsg.passwordConfirmation && (
              <div className="txt_error">{validationMsg.passwordConfirmation}</div>
            )}
          </div>
        </div>
        <div className="text-center">
          <TButton
            title={t('buttonResetPassword')}
            className="btn primary-yellow btn-sp btn-reset-password"
            type="submit"
          />
        </div>
      </form>
      <ModalResetSuccess show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}
