// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { AiOutlineMenu } from 'react-icons/ai';
import './headerSP.scss';
import NotifyNews from '../../notifyNews';
import { useHistory } from 'react-router-dom';

export default function HeaderSP(props) {
  const { t } = useTranslation();
  const [isShowSubMenu, setShowSubMenu] = useState(false);

  const {
    gotoLogin,
    isLogin,
    handleLogout,
    gotoMyCollection,
    gotoNews,
    gotoRegister,
    gotoMyPage,
    gotoPurchaseHistory,
  } = props;

  const toggleMenu = () => {
    setShowSubMenu((isShowSubMenu) => !isShowSubMenu);
  };

  const history = useHistory();

  return (
    <header className="ol-header-sp">
      <div className="logo">
        <div
          onClick={() => {
            history.push('/');
          }}
          className="brand-kuzipa"
        >
          <svg
            id="Logo_black"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="130.96"
            height="28.526"
            viewBox="0 0 130.96 28.526"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_4764"
                  data-name="Rectangle 4764"
                  width="130.96"
                  height="28.526"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g id="Group_7430" data-name="Group 7430" clipPath="url(#clip-path)">
              <path
                id="Path_57587"
                data-name="Path 57587"
                d="M56.256,3.682a15.521,15.521,0,0,0,2.792,2.792,15.521,15.521,0,0,0-2.792,2.792,15.521,15.521,0,0,0-2.792-2.792,15.521,15.521,0,0,0,2.792-2.792m0-3.682a.327.327,0,0,0-.308.215A12.067,12.067,0,0,1,50,6.166a.328.328,0,0,0,0,.617,12.068,12.068,0,0,1,5.951,5.951.328.328,0,0,0,.617,0,12.068,12.068,0,0,1,5.951-5.951.328.328,0,0,0,0-.617A12.067,12.067,0,0,1,56.564.215.327.327,0,0,0,56.256,0"
                transform="translate(-30.903 0)"
              />
              <path
                id="Path_57588"
                data-name="Path 57588"
                d="M74.329,29.483a13.014,13.014,0,0,0,1.554,1.554,13.039,13.039,0,0,0-1.554,1.553,13.039,13.039,0,0,0-1.554-1.553,13.013,13.013,0,0,0,1.554-1.554m0-3.477a.323.323,0,0,0-.3.2A9.713,9.713,0,0,1,69.5,30.736a.324.324,0,0,0,0,.6,9.711,9.711,0,0,1,4.527,4.527.324.324,0,0,0,.6,0,9.711,9.711,0,0,1,4.527-4.527.324.324,0,0,0,0-.6,9.713,9.713,0,0,1-4.527-4.527.323.323,0,0,0-.3-.2"
                transform="translate(-43.018 -16.144)"
              />
              <path
                id="Path_57589"
                data-name="Path 57589"
                d="M200.3,19.531h0L214.1,6.544h-13.23l-13.8,12.986,6.613,6.225,7.259,6.833h13.23Z"
                transform="translate(-116.126 -4.063)"
              />
              <path
                id="Path_57590"
                data-name="Path 57590"
                d="M326.031,36.435,320.2,30.808h9.529v9.2Z"
                transform="translate(-198.773 -19.125)"
              />
              <path
                id="Path_57591"
                data-name="Path 57591"
                d="M326.031,12.172,320.2,6.545h9.529v9.2Z"
                transform="translate(-198.773 -4.063)"
              />
              <path
                id="Path_57592"
                data-name="Path 57592"
                d="M291.544,24.947h-9.5V6.545H271.321v18.4l.047.049,7.789,7.521h20.225Z"
                transform="translate(-168.428 -4.063)"
              />
              <path
                id="Path_57593"
                data-name="Path 57593"
                d="M10.724,9.152H1.845L0,34.208H8.832L9.79,21.52l1.4,1.525.265.289,2.022,2.2,7.883,8.6H33.637Z"
                transform="translate(0 -5.681)"
              />
              <path
                id="Path_57594"
                data-name="Path 57594"
                d="M130.592,28.572l-6.937-7.183,7-7.253h.016V6.545H103.56v7.591h14.788l-17.82,18.454h12.311l4.661-4.826,4.661,4.826h8.432Z"
                transform="translate(-62.404 -4.063)"
              />
            </g>
          </svg>
        </div>

        <OutsideClickHandler
          onOutsideClick={() => {
            setShowSubMenu(false);
          }}
        >
          <AiOutlineMenu onClick={toggleMenu} />
          {isShowSubMenu && (
            <div className="sub-menu">
              {isLogin ? (
                <div className="sub-menu-item-wrap">
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      setShowSubMenu(false);
                      gotoMyPage();
                    }}
                  >
                    {t('menu.myPage')}
                  </div>
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      setShowSubMenu(false);
                      gotoPurchaseHistory();
                    }}
                  >
                    {t('menu.purchaseHistory')}
                  </div>
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      setShowSubMenu(false);
                      handleLogout();
                    }}
                  >
                    {t('logout')}
                  </div>
                </div>
              ) : (
                <div className="sub-menu-item-wrap">
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      gotoLogin();
                      setShowSubMenu(false);
                    }}
                  >
                    {t('login')}
                  </div>
                </div>
              )}
            </div>
          )}
        </OutsideClickHandler>
      </div>

      <div className="menu">
        {isLogin && (
          <div
            className="my-collection d-flex align-items-center justify-content-center"
            onClick={gotoMyCollection}
          >
            <svg
              id="card_giftcard_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path id="Path_53318" data-name="Path 53318" d="M0,0H18V18H0Z" fill="none" />
              <path
                id="Path_53319"
                data-name="Path 53319"
                d="M14.6,4.737H13.074a2.014,2.014,0,0,0,.126-.684A2.074,2.074,0,0,0,11.1,2a2.11,2.11,0,0,0-1.75.924L9,3.382l-.35-.465A2.121,2.121,0,0,0,6.9,2,2.074,2.074,0,0,0,4.8,4.053a2.014,2.014,0,0,0,.126.684H3.4A1.374,1.374,0,0,0,2.007,6.105L2,13.632A1.38,1.38,0,0,0,3.4,15H14.6A1.38,1.38,0,0,0,16,13.632V6.105A1.38,1.38,0,0,0,14.6,4.737ZM11.1,3.368a.684.684,0,1,1-.7.684A.694.694,0,0,1,11.1,3.368Zm-4.2,0a.684.684,0,1,1-.7.684A.694.694,0,0,1,6.9,3.368Zm7.7,10.263H3.4V12.263H14.6Zm0-3.421H3.4V6.105H6.956L5.5,8.042l1.134.8L8.3,6.625,9,5.695l.7.931,1.666,2.217,1.134-.8L11.044,6.105H14.6Z"
                transform="translate(0 0)"
                fill="#f55076"
              />
            </svg>

            {t('menu.myCollection')}
          </div>
        )}
        <div
          className="user-guide d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/guide');
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="レイヤー_1" data-name="レイヤー 1" transform="translate(-84 86)">
              <path
                id="Path_52141"
                data-name="Path 52141"
                d="M0,0H18V18H0Z"
                transform="translate(84 -86)"
                fill="none"
              />
              <path
                id="Path_52551"
                data-name="Path 52551"
                d="M32.39,16.173l4.228-4.228a.869.869,0,0,0,.253-.61V3.882a.865.865,0,0,0-1.476-.61l-3,3Z"
                transform="translate(60.631 -85.478)"
                fill="#40b569"
              />
              <path
                id="Path_52139"
                data-name="Path 52139"
                d="M8.083,15.553l3.611-3.616a.87.87,0,0,0,.253-.61V3.873a.863.863,0,0,0-1.473-.61l-3,3-3-3.006A.863.863,0,0,0,3,3.873v7.452a.87.87,0,0,0,.253.61l3.611,3.618a.859.859,0,0,0,1.219,0Z"
                transform="translate(85.504 -85.469)"
                fill="none"
                stroke="#40b569"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
          </svg>
          {t('menu.userGuide')}
        </div>
        <div className="wrap-notify-sp">
          <div
            className="notice d-flex align-items-center justify-content-center"
            onClick={gotoNews}
          >
            <svg
              id="notifications_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="17.997"
              height="17.997"
              viewBox="0 0 17.997 17.997"
            >
              <path id="Path_52137" data-name="Path 52137" d="M0,0H18V18H0Z" fill="none" />
              <path
                id="Path_52138"
                data-name="Path 52138"
                d="M10,17.122a1.5,1.5,0,0,0,1.5-1.5h-3A1.5,1.5,0,0,0,10,17.122Zm4.5-4.5V8.874a4.561,4.561,0,0,0-3.374-4.739v-.51a1.125,1.125,0,0,0-2.25,0v.51A4.563,4.563,0,0,0,5.5,8.874v3.749L4,14.123v.75H16v-.75Zm-1.5.75H7v-4.5A3.075,3.075,0,0,1,10,5.5a3.075,3.075,0,0,1,3,3.374Z"
                transform="translate(-1.001 -0.625)"
                fill="#ff6c00"
              />
            </svg>
            {t('menu.news')}
          </div>
          {isLogin && <NotifyNews />}
        </div>

        <div
          className="header-list-lottery-sp d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/lottery');
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.286"
            height={12}
            viewBox="0 0 16.286 12"
          >
            <g
              id="Rectangle_4668"
              data-name="Rectangle 4668"
              transform="translate(0.286)"
              fill="none"
              stroke="#f55076"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <rect width={16} height={11} stroke="none" />
              <rect x="0.75" y="0.75" width="14.5" height="9.5" fill="none" />
            </g>
            <g
              id="Path_57533"
              data-name="Path 57533"
              transform="matrix(0.966, -0.259, 0.259, 0.966, 0.758, 8.292)"
              fill="#f55076"
            >
              <path
                d="M 4.519876003265381 3.052529573440552 L 0.6061152219772339 2.076717376708984 L 1.494024634361267 -1.484504461288452 L 1.54073691368103 -1.67185914516449 L 4.519876003265381 3.052529573440552 Z"
                stroke="none"
              />
              <path
                d="M 1.742537260055542 -0.4144409894943237 L 1.212225914001465 1.712529540061951 L 3.432923316955566 2.266212701797485 L 1.742537260055542 -0.4144409894943237 M 1.338934421539307 -2.929274082183838 L 5.606824398040771 3.838845729827881 L 4.76837158203125e-06 2.440905809402466 L 1.338934421539307 -2.929274082183838 Z"
                stroke="none"
                fill="#f55076"
              />
            </g>
            <path
              id="Path_57536"
              data-name="Path 57536"
              d="M297.741,72.29l8.983,7.929"
              transform="translate(-296.726 -70.003)"
              fill="none"
              stroke="#f55076"
              strokeWidth={2}
            />
          </svg>

          {t('menu.listLottery')}
        </div>
        {!isLogin ? (
          <div
            className="register-account d-flex align-items-center justify-content-center"
            onClick={gotoRegister}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.014"
              height="14.84"
              viewBox="0 0 12.014 14.84"
            >
              <g id="Group_7435" data-name="Group 7435" transform="translate(-248.993 -1449.094)">
                <path
                  id="Path_57595"
                  data-name="Path 57595"
                  d="M232.938,73.032A3.938,3.938,0,1,1,229,69.094a3.939,3.939,0,0,1,3.939,3.938"
                  transform="translate(26 1380)"
                  fill="#2f92d0"
                />
                <path
                  id="Path_57596"
                  data-name="Path 57596"
                  d="M235.007,83.934H222.993c0-2.57,2.69-6.456,6.007-6.456s6.007,3.886,6.007,6.456"
                  transform="translate(26 1380)"
                  fill="#2f92d0"
                />
              </g>
            </svg>
            {t('menu.register')}
          </div>
        ) : (
          <div />
        )}
      </div>
    </header>
  );
}
