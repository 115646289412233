// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import styled from 'styled-components';
import TopHeader from '../common/topHeader';
import ScrollButton from './top/backToTop/ScrollButton';

const QuestionItem = styled.div`
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
const TitleFirstChild = styled.div`
  font-size: 30px;
  letter-spacing: 0.1em;
  line-height: 40px;
  color: #464646;
  padding: 0 0 30px 0;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    padding: 15px 0;
  }
`;
const Title = styled.div`
  font-size: 30px;
  letter-spacing: 0.1em;
  line-height: 40px;
  color: #464646;
  padding: 50px 0 30px 0;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    padding: 15px 0;
  }
`;

const Question = styled.div`
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 24px;
  color: #000000;
  font-weight: bold;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin-top: 20px;
  }
`;

const Border = styled.div`
  border: 1px solid #707070;
  width: 100%;
  margin: 20px 0;
  opacity: 0.4;
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
`;
const Answer = styled.div`
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 24px;
  margin-bottom: 30px;
  color: #000000;

  @media screen and (max-width: 768px) {
    font-size: 8px;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;
const Browser = styled.div`
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`;
export default function Questions() {
  return (
    <>
      <TopHeader title="よくある質問" />
      <div className="main-content form-static">
        <QuestionItem>
          <TitleFirstChild>ベスくじについて</TitleFirstChild>
          <Question>Q1 ベスくじとは？</Question>
          <Border />
          <Answer>
            かんたん・手軽にワクワク体験。当たって嬉しい、ハズレなしのオンラインくじです。
            <br />
            購入した商品は後日、自宅で受け取ることができます。遊び方については、「初めての方へ」もご参照ください。
          </Answer>

          <Question>
            Q2 スマートフォン（iOS・Android）、パソコン、どちらでも購入できますか？
          </Question>
          <Border />
          <Answer>
            スマートフォン（iOS・Android）、パソコンで購入可能です。フィーチャーフォンには対応しておりませんので
            ご了承ください。
          </Answer>

          <Question>Q3 ご利用環境について</Question>
          <Border />
          <Answer>
            ベスくじを快適にご利用いただくために下記ブラウザを推奨いたします。
            <br />
            下記の動作確認環境以外では、正しく動作しない場合がございます。
            <br />
            ■PC
            <br />
            Microsoft Edge 最新版
            <br />
            Mozilla Firefox 最新版
            <br />
            Google Chrome 最新版
            <br />
            Safari 最新版
            <Browser>
              ■モバイル
              <br />
              iOS 11以降のChrome/Safari
              <br />
              Android 6以降のChrome
              <br />
            </Browser>
          </Answer>

          <Question>Q4 会員登録はどうすればよいですか？</Question>
          <Border />
          <Answer>
            サイト上部に表示されている「会員登録」から新規登録が行えます。
            <br />
            会員登録とログインの後、各販売ページよりご購入いただけます。
            <br />
            詳しくは下記ガイドページもご確認ください。
            <br />
            <a href="/guide">初めての方へ</a>
            <br />
          </Answer>

          <Question>Q5「+On Challenge」とは？</Question>
          <Border />
          <Answer>
            購入した同一タイトルのくじ商品とは別の自動抽選機能です。
            <br />
            購入したくじ商品とは別に、自動抽選されて当せんする可能性のある賞です。
            <br />
            当該自動抽選は本サイト上の個別のサービス・ページに表示されます。当該自動抽選機能は、当せんを確約するものではありません。
          </Answer>

          <Question>Q6 破棄機能とは？</Question>
          <Border />
          <Answer>
            購入した商品を破棄することが可能です。
            <br />
            不要な商品を破棄することで、ポイントに変更することができます。
          </Answer>

          <Question>Q7 ポイントについて</Question>
          <Border />
          <Answer>
            ベスくじでご利用いただけるポイントサービスです。
            <br />
            ポイントを利用してくじを引くことが可能です。
            <br />
            ポイントの保有期間は、付与から1年間となります。
            <br />
            ポイント保有状況は、マイページよりご確認いただけます。
            <br />
            ポイントは1ポイント1円として、どのタイトルのくじでもご利用可能です。
          </Answer>
        </QuestionItem>

        <QuestionItem>
          <Title>購入について</Title>
          <Question>Q1 年齢制限はありますか？</Question>
          <Border />
          <Answer>未成年者の方々は、保護者の同意を必ず得る必要があります。</Answer>

          <Question>Q2 購入の受付メールは届きますか？</Question>
          <Border />
          <Answer>
            購入時点ではメールでのご案内はございません。商品の発送が完了した際にメールでお知らせいたします。
          </Answer>

          <Question>Q3 注文内容・当せん結果はどこで確認できますか？</Question>
          <Border />
          <Answer>
            「メニュー」の「購入履歴」をご確認ください。詳細を確認することができます。
          </Answer>

          <Question>Q4 購入後のキャンセルはできますか？</Question>
          <Border />
          <Answer>購入後のキャンセルは不可となっております。</Answer>

          <Question>Q5 利用できる支払い方法は？</Question>
          <Border />
          <Answer>
            ・クレジットカード（VISA、MASTER、JCB、AMEX、DINERS）
            <br />
            ・d払い
            <br />
            ・ソフトバンクまとめて支払い
            <br />
          </Answer>

          <Question>Q6 購入後にクレジットカードを変更したい</Question>
          <Border />
          <Answer>
            購入後のクレジット情報の変更はできかねますので、正確な情報を入力ください。
            <br />
            ※なお、サービスの特性上、お客様都合でのキャンセル・返品・交換は承っておりません。
          </Answer>

          <Question>Q7 購入後に支払い方法を変更したい</Question>
          <Border />
          <Answer>購入後のお支払い方法の変更はできかねます。</Answer>

          <Question>Q8 入力したカード番号、暗証番号が違うと言われて支払認証できない</Question>
          <Border />
          <Answer>
            お支払い時の決済認証が得られなかった理由については、弊社で確認することができません。詳細はご利用のカード会社、各キャリアへご確認をお願いいたします。
          </Answer>

          <Question>Q9 領収書は発行してもらえますか？</Question>
          <Border />
          <Answer>領収書の発行はしておりません。</Answer>
        </QuestionItem>

        <QuestionItem>
          <Title>配送について</Title>
          <Question>Q1 配送手数料はいくらかかりますか？</Question>
          <Border />
          <Answer>
            配送手数料は各商品ページをご確認ください。
            <br />
            ※注文個数が1回分の配送手数料でお届けできる個数を超えた場合、以降、上限の個数ごとに配送手数料が発生します。
            <br />
            【例】くじ20個まで1回分の手数料でお届けの場合
            <br />
            ・購入数1-20個までは複数回のご注文でも1回分の配送手数料でお届けします。
            <br />
            ・購入数21-40個までは複数回のご注文でも2回分の配送手数料でお届けします。
            <br />
          </Answer>

          <Question>Q2 発送状況について知りたい</Question>
          <Border />
          <Answer>発送状況は、「メニュー」の「購入履歴」よりご確認いただけます。</Answer>

          <Question>Q3 購入後に送付先を変更できますか？</Question>
          <Border />
          <Answer>
            購入済み後のお届け先変更はログイン後、「メニュー」の「購入履歴」から変更が可能です。
            <br />
            ※変更期限内に限ります。
            <br />
            ※既に配送済みの商品のお届け先は出来かねますので、ご注意ください。
            <br />
          </Answer>

          <Question>Q4 海外対応はしていますか？ (Can this service be used outside Japan?)</Question>
          <Border />
          <Answer>
            当サービスは日本国内においてのみご利用可能となります。
            <br />
            We do not accept orders from outside of Japan. We can not send goods to outside of
            Japan.
          </Answer>

          <Question>Q5 配送はいつになりますか？</Question>
          <Border />
          <Answer>
            各商品の配送時期に関しましては、各商品ページのお届け目安をご確認ください。
            <br />
            ※受注生産品が多く含まれるため、ご注文から配送までにお時間を頂く場合がございます。予めご了承ください。
            <br />
            ※お届けする商品が複数の場合、商品によっては同日のお届けとならず時期が若干ずれる場合があります。予めご了承ください。
          </Answer>

          <Question>Q6 別々の注文番号の商品を同梱発送してもらうことはできますか？</Question>
          <Border />
          <Answer>
            誠に申し訳ございませんが、同梱希望は承っておりません。なお、別々の注文番号の場合でも、同梱発送させていただく場合がございます。
          </Answer>

          <Question>Q7 長期不在等の理由で商品が受け取れない場合は？</Question>
          <Border />
          <Answer>
            商品お届け時にご不在の場合は、配送業者が投函する「ご不在連絡票」に従い、商品のお受け取り手続きをお願いいたします。
            <br />
            発送完了後3日が経過しても初回配達がない場合は、住所不明の可能性がございますので、配送業者へご連絡ください。
          </Answer>

          <Question>Q8 保管期間は？</Question>
          <Border />
          <Answer>
            長期不在や住所不明で配送業者の保管期間内にお受け取りいただけなかった場合、商品はベスくじに返送されます。
            <br />
            返送から2ヶ月が経過した時点でご注文はキャンセルとさせていただきますので、お早めにベスくじまで再発送のご依頼をお願いいたします。
            <br />
            ※再発送にかかる往復送料等はお客様のご負担となります。
          </Answer>
        </QuestionItem>

        <QuestionItem>
          <Title>キャンセル・交換・返品について</Title>
          <Question>Q1 キャンセル・交換・返品はできますか？</Question>
          <Border />
          <Answer>
            【オンラインくじ】というサービスの特性上、ご注文完了後（ご注文に基づく「抽選結果」の表示による商品確定後）はいかなる場合におきましてもお客様都合によるキャンセル・返品・交換は承っておりません。予めご了承ください。
          </Answer>

          <Question>Q2 商品に不良があったので交換してほしい</Question>
          <Border />
          <Answer>
            お届けした商品に不具合、商品違い又は数量不足等があった場合には、商品到着後7日以内に当社所定の窓口(support@beskuzi.com)までご連絡ください。
            <br />
            速やかに交換等の対応をさせていただきます。（送料・手数料当社負担）
            <br />
            ・ご注文番号
            <br />
            ・送付先氏名
            <br />
            ・お困りの状況詳細（例：B-1に傷が付いている、C-2が不足している、キャンペーンの景品が破損している等）
            <br />
            ・商品不良の状態を確認できる写真
            <br />
            ※商品不良に関しましては購入者ご本人様に限り、交換を承ります。譲渡、交換後の商品の交換は承りかねますので、予めご了承ください。
            <br />
            ※商品不良に関しましては一般的な流通商品と比べて著しく状態の悪いもののみが交換対象となります。当方で販売に支障のない商品と判断させていただいた場合は、交換をお受けできないこともございますので、予めご了承ください。
            <br />
          </Answer>
        </QuestionItem>

        <QuestionItem>
          <Title>その他</Title>
          <Question>Q1 退会について</Question>
          <Border />
          <Answer>
            「マイページ」下部にある「退会はこちら」よりお手続きをお願いいたします。
            <br />
            一度退会したベスくじログイン用メールアドレスと同じメールアドレスでベスくじの会員再登録は可能です。
            <br />
            ※退会前のデータは保持されます
          </Answer>

          <Question>Q2 個人情報の取り扱いについて知りたい</Question>
          <Border />
          <Answer>
            お客様からいただいた個人情報は商品の発送とご連絡以外には一切使用いたしません。
            <br />
            ※お客様からいただいた個人情報は当社の「プライバシーポリシー」に基づいてお取扱いいたします。
          </Answer>

          <Question>Q3 その他のお問い合わせについて</Question>
          <Border />
          <Answer>
            上記にて解決しないお問い合わせに関しましては お問い合わせ より24時間受け付けております。
            <br />
            ※お問い合わせへのご返答は、翌営業日以降に順次お返事させていただきます。
          </Answer>
        </QuestionItem>
      </div>
      <ScrollButton />
    </>
  );
}
