// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import '../forgotPassword/styles.scss';
import TButton from '../../components/common/button';
import { validateEmail } from '../../validations/validateEmail';
import BoxRequired from '../../components/common/boxRequired';
import './styles.scss';
import {
  validateConfirmEmailDelivery,
  validateContent,
  validateName,
} from '../../validations/validateAddress';
import { AuthApis } from '../../services/auth';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { listsErrorNotShowToast } from '../../constants';
import { UserInfoApis } from '../../services/userInfo';
import JSCookie from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';

export default function ContactUs() {
  const authToken = JSCookie.get('token');
  const { openMessageError } = useMessage();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [content, setContent] = useState('');
  const [validationMsg, setValidationMsg] = useState({});
  const [checkedPolicy, setCheckedPolicy] = useState(false);

  const history = useHistory();

  const validateAll = () => {
    const error = {
      name: '',
      email: '',
      confirmEmail: '',
      content: '',
    };
    error.name = validateName(name.trim());
    error.email = validateEmail(email.trim());
    error.confirmEmail = validateConfirmEmailDelivery(email.trim(), confirmEmail.trim());
    error.content = validateContent(content);
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i++) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    const formData = {
      name: name,
      email: email,
      content: content,
    };
    try {
      const res = await AuthApis.contactUs(formData);
      const { data } = res;
      setName('');
      setEmail('');
      setConfirmEmail('');
      setContent('');
      setValidationMsg({});
      const messageSuccess = data.message;
      history.push({ pathname: '/contact/success', state: messageSuccess });
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getUserInfo = async () => {
    const formData = {
      info: 'profile',
    };
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(formData);
      const { data } = res;
      setEmail(data?.email);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (authToken) {
      getUserInfo();
    }
  }, []);

  return (
    <div>
      <TopHeader title={t('headerContact')} />
      <form
        className="main-content forgot-password"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="form-forgot-password">
          <div className="title-contact">{t('titleContact')}</div>
          <div>
            <BoxRequired text={t('txtName')} />
          </div>
          <div className="space-contact">
            <div className="input-group">
              <input
                type="text"
                maxLength="30"
                placeholder="姓名を入力してください"
                className="form-control border-input"
                value={name}
                onChange={(event) => setName(event.target.value)}
                onBlur={() => setName(name.trim())}
              ></input>
            </div>
            {validationMsg.name && <div className="txt_error">{validationMsg.name}</div>}
          </div>
          <div>
            <BoxRequired text={t('txtEmail')} />
          </div>
          <div className="space-contact">
            <div className="input-group">
              <input
                type="text"
                maxLength="255"
                placeholder="test@example.com"
                className="form-control border-input"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={() => setEmail(email.trim())}
              ></input>
            </div>
            {validationMsg.email && <div className="txt_error">{validationMsg.email}</div>}
          </div>
          <div>
            <BoxRequired text={t('textEmailConfirmContact')} />
          </div>
          <div className="space-contact">
            <div className="input-group">
              <input
                type="text"
                maxLength="255"
                placeholder="test@example.com"
                className="form-control border-input"
                value={confirmEmail}
                onChange={(event) => setConfirmEmail(event.target.value)}
                onBlur={() => setConfirmEmail(confirmEmail.trim())}
              ></input>
            </div>
            {validationMsg.confirmEmail && (
              <div className="txt_error">{validationMsg.confirmEmail}</div>
            )}
          </div>
          <div>
            <BoxRequired text={t('txtContent')} />
          </div>
          <div>
            <div className="input-textarea">
              <textarea
                type="text"
                rows="10"
                maxLength="2000"
                className="form-control border-input"
                value={content}
                onChange={(event) => setContent(event.target.value)}
                onBlur={() => setContent(content.trim())}
              ></textarea>
            </div>
            {validationMsg.content && <div className="txt_error">{validationMsg.content}</div>}
          </div>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <input
              className="checkbox-input"
              type="checkbox"
              value={checkedPolicy}
              onChange={() => setCheckedPolicy(!checkedPolicy)}
            />
            <label className="title-checkbox">
              お問い合わせにあたり、
              <span
                className="link-policy"
                onClick={() => window.open('https://www.besteight.co.jp/company/detail/?id=119')}
              >
                プライバシーポリシーと個人情報の取り扱い
              </span>
              に同意します。
            </label>
          </div>
          <div className="text-center btnContact">
            <TButton
              title={t('buttonContact')}
              className="btn info btn-sp"
              type="submit"
              disabled={!checkedPolicy}
            />
          </div>
        </div>
        <div className="about-contact">
          <Table>
            <tbody>
              <tr>
                <th>※</th>
                <td>
                  お問い合わせの前に、端末キャリア側の迷惑メールフィルターや受信拒否設定などにより、
                  <br />
                  メールが受信できない設定になっていないか必ずご確認ください。
                  <br />
                  メール受信拒否設定を行っている場合は、「@hike.inc」ドメインからのメールが受信できるように設定してください。
                </td>
              </tr>
              <tr>
                <th>※</th>
                <td>
                  ご質問にはメールで返信いたします。ご入力いただくメールアドレスにお間違いがないようご注意ください。
                </td>
              </tr>
              <tr>
                <th>※</th>
                <td>
                  お問い合わせ受付後、確認メールが送信されます
                  <br />
                  端末の受信設定により確認メールが届かない場合、お問い合わせの回答も受信できませんので、ご注意ください。
                </td>
              </tr>
              <tr>
                <th>※</th>
                <td>
                  お問い合わせは24時間受け付けておりますが、混雑状況やご質問の内容により、
                  <br />
                  返信までにお時間を頂く場合がございます。あらかじめご了承ください。
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </form>
      <ScrollButton />
    </div>
  );
}
