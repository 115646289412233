// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertRankName } from '../../../../utils/convertSaleStatus';
import PreviewPhoto from '../PurchaseHistoryDetailPC/PreviewPhoto';
export default function ListPrize(props) {
  const { t } = useTranslation();
  const { prize } = props;
  const [modalShow, setModalShow] = useState(false);

  const handleOpenPreview = () => {
    setModalShow(true);
  };

  return (
    <>
      <div className="item-prize-sp" onClick={handleOpenPreview}>
        <div className="title-prize-sp">{`${
          prize.custom_name ? prize.custom_name : convertRankName(prize.rank_name)
        } - ${prize.number_order} ${prize.title}`}</div>
        <div className="image-prize">
          <div>
            <img src={prize?.image?.thumbnail_url} alt="img" />
          </div>
        </div>

        <div className="number-prize-sp">
          {t('textNumberPrize')} <span>{prize.quantity}</span> <div className="unit">個</div>
        </div>
      </div>
      <PreviewPhoto show={modalShow} onHide={() => setModalShow(false)} data={prize} />
    </>
  );
}
