// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import InformationNews from '../../components/pages/newsDetail/InformationNews';
import Thumbnail from '../../components/pages/newsDetail/Thumbnail';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { GetNewsDetail } from '../../services/newsDetail';
import OverviewNews from '../../components/pages/newsDetail/OverViewNews';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import { GUIDE, facebookIcon, twitterIcon } from '../../../assets/imgs';
import { shareLink } from '../../utils/share';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { listsErrorNotShowToast } from '../../constants';
import queryString from 'query-string';
import useMatatag from '../../hooks/useMatatag';

export default function NewsDetail() {
  const dispatch = useAppDispatch();
  const [data, setData] = useState();
  const { openMessageError } = useMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const {
    auth: { userInfo },
  } = useAppSelector((state) => state);
  const user_id = userInfo?.id;
  const getNewsDetail = async () => {
    dispatch(showLoading());
    const params = {
      news_id: id,
      user_id: user_id,
      token: search.token ? search.token : null,
    };
    try {
      const res = await GetNewsDetail.getData(params);
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useMatatag({
    title: data?.title,
    description: data?.content,
    url: `${process.env.APP_DOMAIN}news/${id}`,
    image: data?.thumbnail,
    keyword:
      data?.title + ',ベスくじ,オンラインスクラッチくじ, オンラインくじ,アイドル, くじイベント名',
    twitterCard: 'summary_large_image',
    twitterSite: '@BesKuZi_jp',
  });

  useEffect(() => {
    getNewsDetail();
  }, [id]);

  const handleShare = (type) => {
    return window.open(shareLink(type, `${process.env.APP_ENDPOINT}/share/news/${id}`));
  };

  return (
    <>
      <TopHeader title={t('headerNewsDetail')} />
      {data ? (
        <div className="main-content">
          <div className="info-news-detail">
            <OverviewNews data={data} />
            <div>
              <Thumbnail data={data} />
            </div>
            <InformationNews data={data} />

            <div className="share-sns">
              <div className="title">
                <img src={GUIDE.iconLeft} alt="icon" className="icon" />
                <div className="text-share">シェアする</div>
                <img src={GUIDE.iconRight} alt="icon" className="icon" />
              </div>
              <div className="icon-wrapper">
                <img
                  src={facebookIcon}
                  alt="icon"
                  className="icon icon-fb"
                  onClick={() => handleShare('fb')}
                />
                <img
                  src={twitterIcon}
                  alt="icon"
                  className="icon"
                  onClick={() => handleShare('tw')}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      <ScrollButton />
    </>
  );
}
