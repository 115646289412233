// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useHistory } from 'react-router-dom';
import { ON_CHALLENGE } from '../../../../constants';
import { convertRankName } from '../../../../utils/convertSaleStatus';

export default function PrizeItem(props) {
  const { data, type, totalPrize, onClick } = props;

  const history = useHistory();
  const pathLotteryTrial = '/result/trial';
  const pathSite = history.location.pathname;

  return (
    <>
      {data && (
        <div className="card-prize card" onClick={onClick}>
          <div className="card-img-prize">
            <div>
              <img
                src={
                  pathLotteryTrial === pathSite
                    ? data?.image?.thumbnail_url
                    : data?.image[0].thumbnail_url
                }
                alt={data.title}
                className="prizeItemImg"
              />
            </div>
          </div>
          <div className="card-body p-0">
            <div className="name">
              <div className="name-content">
                {`${data.custom_name ? data.custom_name : convertRankName(data.rank_name)} - ${
                  data.number_order
                } ${data.title}`}
              </div>
            </div>
            <div className="quantity">
              <div className="text-center" style={{ fontSize: 12, letterSpacing: 1.2 }}>
                {type === ON_CHALLENGE && !data.quantity ? 'ハズレ個数' : '当選個数'}
              </div>
              <div className="text-center fw-bold">
                <span style={{ fontSize: 24, marginRight: 2 }}>
                  {type === ON_CHALLENGE && !data.quantity ? totalPrize : data.quantity}
                </span>
                <span style={{ fontSize: 16 }}>個</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
