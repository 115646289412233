// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

import useMessage from '../../../components/common/toast/UseMessage';
import TopHeader from '../../../components/common/topHeader';
import ScratchItem from '../../../components/pages/scratch/scratchRetail/ScratchItem';
import { PurchaseApis } from '../../../services/purchase';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';

import './index.scss';
import { useHistory } from 'react-router-dom';
import {
  GiftBoxBlue,
  GiftBoxRed,
  GiftBoxYellow,
  OpenBoxYellow,
  OpenBoxBlue,
  OpenBoxRed,
  ScratchBackground,
  ScratchBackgroundYellow,
  SkipButton,
  ScratchScale,
  NextButton,
  NewTag,
  Box1,
  Box2,
  Box3,
  WChanceBG,
  WChanceTicket,
  WChanceMiss,
  WChanceON,
  WChanceONfooter,
  Congratulations,
  wChangeAnimation2,
  GetResultPC,
  GetResultSP,
} from '../../../../assets/imgs';
import { ON_CHALLENGE, listsErrorNotShowToast, BREAK_POINT, LIST_RANK } from '../../../constants';
import RenderFooterBuyTogether from '../../../components/pages/scratch/RenderFooterBuyTogether';

const BoxAnimation = styled.div`
  ${(props) => props.isShow && 'animation: zoom-in-zoom-out 2s ease-in-out normal;'}
`;

const ScratchWrapper = styled.div`
  background-image: ${(props) =>
    props.isPackage ? `url(${ScratchBackgroundYellow})` : `url(${ScratchBackground})`};
  background-size: 100% 100%;
`;

const ScratchWChanceWrapper = styled.div`
  background-image: ${(props) =>
    props.isShowWchangeAnimation2 ? `url(${ScratchBackground})` : `url(${WChanceBG})`};
  background-size: 100% 100%;
`;

const ButtonSkip = styled.div`
  background-image: url(${SkipButton});
  width: 165px;
  height: 52px;
  background-size: 165px 52px;
  position: absolute;
  top: 14px;
  right: 12px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    top: 10px;
    right: 10px;
    width: 90px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;
const ButtonSkipCustom = styled.div`
  background-image: url(${SkipButton});
  width: 165px;
  height: 52px;
  background-size: 165px 52px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 90px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const STEP = {
  OPEN_MODAL: 1,
  SHOW_SCRATCH: 2,
  SCRATCH: 3,
  SHOW_RESULT: 4,
  SHOW_W_CHANCE: 5,
  SHOW_W_CHANCE_TICKET: 6,
  SHOW_W_CHANCE_ANIMATION_1: 7,
  SHOW_W_CHANCE_ANIMATION_2: 8,
  SHOW_W_CHANCE_RESULT: 9,
};

export const STEP_OPEN_BOX = {
  CLOSE: 0,
  OPEN: 1,
  PUSH_CARD: 2,
};

const GETResultPC = styled.div`
  background-image: url(${GetResultPC});
  background-size: cover;
  width: 200px;
  height: 94px;
  position: relative;
  left: -100px;
  top: 21px;
`;

const GETResultSP = styled.div`
  background-image: url(${GetResultSP});
  background-size: cover;
  width: 172px;
  height: 81px;
  position: relative;
  top: 10px;
`;

export default function ScratchUI(props) {
  const { orderId, type, setAdultPurchase } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const pathSite = history.location.pathname;
  const pathLotteryTrial = ['/scratch/trial/retail', '/scratch/trial/package'];
  const { openMessageError } = useMessage();

  const [prizesData, setPrizesData] = useState([]);
  const [animations, setAnimations] = useState([]);
  const [wChanceData, setWChanceData] = useState(undefined);
  const [wChanceFlag, setWChanceFlag] = useState(false);

  const [isShowModalScratch, setShowModalScratch] = useState(false);

  const [stepOpenBox, setStepOpenBox] = useState(STEP_OPEN_BOX.CLOSE);
  const [selectedBoxColor, setColor] = useState('');

  const [ranks, setRanks] = useState(LIST_RANK);

  const [step, setStep] = useState(STEP.OPEN_MODAL);
  const [isScratchWChance, setScratchWChance] = useState(false);

  const [counter, setCounter] = useState(0);

  const convertRankNameToAnimation = (rankName) => {
    switch (rankName) {
      case 'A':
        return step === STEP.SHOW_SCRATCH ? animations.image_rank_a[0] : animations.image_rank_a[1];
      case 'B':
        return step === STEP.SHOW_SCRATCH ? animations.image_rank_b[0] : animations.image_rank_b[1];
      default:
        return animations.image_normal;
    }
  };

  const calcRankQuantity = (rankName, customName) => {
    const newRanks = [...ranks];

    let isExist = false;

    newRanks.forEach((rank) => {
      if (rank.rankName === rankName) {
        rank.value += 1;
        isExist = true;
        return true;
      }
    });

    if (!isExist)
      newRanks.unshift({
        rankName,
        customName,
        value: 1,
      });

    setRanks(newRanks);
  };

  // FAKE_POP_UP_UI
  useEffect(() => {
    const content = document.getElementsByClassName('content');
    content[0].style.backgroundColor = '#707070';
    return () => (content[0].style.backgroundColor = 'unset');
  });

  const [adult, setAdult] = useState();
  const stateScratchTrial = history?.location?.state;

  const [dataTrial, setDataTrial] = useState();
  const handleMoveResult = (id) => {
    if (pathLotteryTrial.includes(pathSite)) {
      history.push({ pathname: '/result/trial', state: dataTrial });
    } else {
      history.push(`/result?order_id=${id}`);
    }
  };

  useEffect(async () => {
    dispatch(showLoading());
    try {
      const res = pathLotteryTrial.includes(pathSite)
        ? await PurchaseApis.scratchTrial({
            quantity: stateScratchTrial?.quantity,
            adultPurchase: stateScratchTrial?.adultPurchaseFlag,
          })
        : await PurchaseApis.scratch({
            order_id: orderId,
          });

      const { data } = res;

      setAdult(data?.adult_purchase_flag);
      setDataTrial(data);
      setAdultPurchase(data?.adult_purchase_flag);
      setWChanceFlag(data.have_on_challenge);
      setAnimations(data.animations);
      const __prizeData = data.prizes.filter((item) => item.rank_name !== ON_CHALLENGE);
      const __rankNameData = data.list_rank_name;
      setRanks((ranksPriv) =>
        ranksPriv.map((rank) => {
          const prizeExist = __rankNameData.find((prize) => prize.rank_name === rank.rankName);
          if (prizeExist && prizeExist.custom_name)
            return { ...rank, customName: prizeExist.custom_name };
          return rank;
        }),
      );

      if (__prizeData) {
        setPrizesData(__prizeData);
      }
      const __wChanceData = data.prizes.find((item) => item.rank_name === ON_CHALLENGE);
      if (__wChanceData) {
        setWChanceData(__wChanceData);
      }
      if (!__prizeData.length && !__wChanceData) {
        handleMoveResult(orderId);
      }
    } catch (err) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  }, []);

  const handleNextStep = (rankName = '') => {
    switch (step) {
      case STEP.OPEN_MODAL:
        if (['A', 'B'].includes(rankName)) {
          setStep(STEP.SHOW_SCRATCH);
        } else {
          setStep(STEP.SCRATCH);
        }
        break;
      case STEP.SHOW_SCRATCH:
        setStep(STEP.SCRATCH);
        break;
      case STEP.SCRATCH:
        setStep(STEP.SHOW_RESULT);
        break;
      case STEP.SHOW_RESULT:
        calcRankQuantity(prizesData[counter].rank_name, prizesData[counter].custom_name);
        if (counter >= prizesData.length - 1) {
          if (!wChanceFlag && typeof wChanceData === 'undefined') {
            setStep(STEP.OPEN_MODAL);
            handleMoveResult(orderId);
          } else {
            setStep(STEP.SHOW_W_CHANCE);
            setScratchWChance(true);
          }
        } else {
          setStep(STEP.OPEN_MODAL);
          if (counter < prizesData.length) {
            setCounter(counter + 1);
          }
        }
        setShowModalScratch(false);
        break;
      case STEP.SHOW_W_CHANCE:
        setStep(STEP.SHOW_W_CHANCE_TICKET);
        setShowModalScratch(true);
        break;
      case STEP.SHOW_W_CHANCE_TICKET:
        setStep(STEP.SHOW_W_CHANCE_ANIMATION_1);
        break;
      case STEP.SHOW_W_CHANCE_ANIMATION_1:
        if (isEmpty(wChanceData)) {
          setStep(STEP.SHOW_W_CHANCE_RESULT);
        } else {
          setStep(STEP.SHOW_W_CHANCE_ANIMATION_2);
        }
        break;
      case STEP.SHOW_W_CHANCE_ANIMATION_2:
        setStep(STEP.SHOW_W_CHANCE_RESULT);
        break;
      default:
        setScratchWChance(false);
        setShowModalScratch(false);
        setStep(STEP.OPEN_MODAL);
        handleMoveResult(orderId);
        break;
    }
  };

  const convertTime = (rankName, order) => {
    let time = 0;

    if (order === 0) {
      switch (rankName) {
        case 'A':
          time = animations?.time_rank_a ? animations?.time_rank_a[0] : 0;
          break;

        case 'B':
          time = animations?.time_rank_b ? animations?.time_rank_b[0] : 0;
          break;

        default:
          time = 2000;
          break;
      }
    } else {
      switch (rankName) {
        case 'A':
          time = animations?.time_rank_a ? animations?.time_rank_a[1] : 0;
          break;

        case 'B':
          time = animations?.time_rank_b ? animations?.time_rank_b[1] : 0;
          break;

        default:
          time = 2000;
          break;
      }
    }
    return time;
  };

  useEffect(() => {
    if (isShowModalScratch) {
      const interval = setInterval(function () {
        if (step === STEP.SHOW_W_CHANCE_ANIMATION_1 || step === STEP.SHOW_W_CHANCE_ANIMATION_2) {
          handleNextStep();
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (isShowModalScratch) {
      const interval = setInterval(function () {
        if (step === STEP.SCRATCH) {
          handleNextStep();
        }
      }, convertTime(prizesData[counter]?.rank_name, 1));
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (isShowModalScratch) {
      const interval = setInterval(function () {
        if (step === STEP.SHOW_SCRATCH) {
          handleNextStep();
        }
      }, convertTime(prizesData[counter]?.rank_name, 0));
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (type === 'package' && stepOpenBox === STEP_OPEN_BOX.PUSH_CARD) {
      let interval = null;

      // WAIT 750 MilI SECOND FOR SHOW ANIMATION PUSH CARD
      setTimeout(() => {
        if (counter < prizesData.length) {
          interval = setInterval(function () {
            if (!isScratchWChance) {
              setShowModalScratch(true);
            } else {
              if (counter < prizesData.length) {
                setCounter(counter + 1);
              }
            }
          }, 750);
        } else {
          if (typeof wChanceData === 'undefined' && !wChanceFlag) {
            handleMoveResult(orderId);
          } else {
            setStep(STEP.SHOW_W_CHANCE);
            setScratchWChance(true);
          }
        }
      }, 200);

      return () => clearInterval(interval);
    }
  }, [type, stepOpenBox, prizesData, counter, isScratchWChance]);

  const renderListScratchItem = () => {
    const result = [];
    for (let i = 0; i < 6; i++) {
      result.push(<ScratchItem onClickItem={() => setShowModalScratch(true)} />);
    }
    return result;
  };

  const formatCountNumber = (number) => {
    if (number < 10) {
      return `00${number}`;
    }
    if (number < 100) {
      return `0${number}`;
    }
    if (number < 1000) return `${number}`;
    return number;
  };

  useEffect(() => {
    if (stepOpenBox === STEP_OPEN_BOX.OPEN) {
      setTimeout(() => {
        setStepOpenBox(STEP_OPEN_BOX.PUSH_CARD);
      }, 2700);
    }
  }, [stepOpenBox]);

  const [isCheckBox, setIsCheckBox] = useState(true);
  const handleOpenBox = (type) => {
    setColor(type);
    setIsCheckBox(false);
    setTimeout(() => {
      setStepOpenBox(1);
    }, 2000);
  };
  return (
    <>
      <TopHeader />
      <div className="popup-wrapper">
        {isScratchWChance ? (
          <ScratchWChanceWrapper
            className="scratch-wrapper"
            isShowWchangeAnimation2={step === STEP.SHOW_W_CHANCE_ANIMATION_2}
          >
            <ButtonSkip
              onClick={() => handleMoveResult(orderId)}
              style={{
                display:
                  step === STEP.SHOW_W_CHANCE_TICKET ||
                  step === STEP.SHOW_W_CHANCE_ANIMATION_1 ||
                  step === STEP.SHOW_W_CHANCE_ANIMATION_2
                    ? 'none'
                    : 'block',
              }}
            />
            <div
              id="w-change-ticket"
              onClick={() => handleNextStep()}
              style={{ cursor: 'pointer' }}
            >
              <img src={WChanceTicket} width="100%" height="100%" />
              <div className="wrap_scratch_item_scale_bg" />
            </div>
            {step === STEP.SHOW_W_CHANCE_TICKET || step === STEP.SHOW_W_CHANCE_ANIMATION_1 ? (
              <></>
            ) : (
              <div id="footer-scratch">
                <span>当たりが出れば＋ON賞GET！</span>
              </div>
            )}
          </ScratchWChanceWrapper>
        ) : (
          <ScratchWrapper className="scratch-wrapper" isPackage={type === 'package'}>
            <div
              style={{
                // visibility: isShowModalScratch ? 'hidden' : 'visible',
                // opacity: isShowModalScratch ? 0 : 1,
                transition: 'all 0.1s ease-in-out',
              }}
            >
              <div
                id="quantity"
                style={{
                  backgroundColor: type === 'package' ? '#000000' : '',
                  color: type === 'package' ? '#FFFFFF' : '',
                }}
              >
                <span>
                  {formatCountNumber(counter + 1) <= formatCountNumber(prizesData.length)
                    ? formatCountNumber(counter + 1)
                    : formatCountNumber(prizesData.length)}{' '}
                  / {formatCountNumber(prizesData.length)}
                </span>
              </div>
              <ButtonSkip onClick={() => handleMoveResult(orderId)} />
            </div>

            {type === 'package' ? (
              <div className="content-scratch-package-wrapper">
                {stepOpenBox ? (
                  <div className="animation" style={{ overflow: 'hidden' }}>
                    <div style={{ display: selectedBoxColor === 'Yellow' ? 'block' : 'none' }}>
                      <img
                        src={OpenBoxYellow}
                        alt="animation"
                        id="animation_open_box"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'unset' : 'none',
                        }}
                      />
                      <img
                        src={GiftBoxYellow}
                        width="100%"
                        id="animation_push_card"
                        style={{ display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'none' : 'block' }}
                      />
                    </div>
                    <div style={{ display: selectedBoxColor === 'Red' ? 'block' : 'none' }}>
                      <img
                        src={OpenBoxRed}
                        alt="animation"
                        id="animation_open_box"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'unset' : 'none',
                        }}
                      />
                      <img
                        src={GiftBoxRed}
                        width="100%"
                        id="animation_push_card"
                        style={{ display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'none' : 'block' }}
                      />
                    </div>
                    <div style={{ display: selectedBoxColor === 'Blue' ? 'block' : 'none' }}>
                      <img
                        src={OpenBoxBlue}
                        alt="animation"
                        id="animation_open_box"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'unset' : 'none',
                        }}
                      />
                      <img
                        src={GiftBoxBlue}
                        width="100%"
                        id="animation_push_card"
                        style={{ display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'none' : 'block' }}
                      />
                    </div>
                  </div>
                ) : (
                  <div id="box-wrapper">
                    <BoxAnimation
                      className="box"
                      id="box1"
                      onClick={isCheckBox ? () => handleOpenBox('Yellow') : () => {}}
                      isShow={selectedBoxColor === 'Yellow'}
                    >
                      <img src={Box1} width="100%" height="100%" />
                    </BoxAnimation>
                    <div className="d-flex justify-content-center">
                      <BoxAnimation
                        className="box"
                        id="box2"
                        onClick={isCheckBox ? () => handleOpenBox('Red') : () => {}}
                        isShow={selectedBoxColor === 'Red'}
                      >
                        <img src={Box2} width="100%" height="100%" />
                      </BoxAnimation>
                      <BoxAnimation
                        className="box"
                        id="box3"
                        onClick={isCheckBox ? () => handleOpenBox('Blue') : () => {}}
                        isShow={selectedBoxColor === 'Blue'}
                      >
                        <img src={Box3} width="100%" height="100%" />
                      </BoxAnimation>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex flex-wrap content-scratch-wrapper">
                {renderListScratchItem()}
              </div>
            )}

            {stepOpenBox ? (
              <div id="scratch-package-result-wrapper">
                <RenderFooterBuyTogether listRank={ranks} />
              </div>
            ) : (
              <div id="footer-scratch">
                {Number(adult) === 1 && <span>好きなプレゼントを選んでね！</span>}
                {Number(adult) === 0 && <span>好きなカードを選んでね！</span>}
              </div>
            )}
          </ScratchWrapper>
        )}
      </div>

      <Modal
        size="lg"
        show={isShowModalScratch}
        centered
        className="modal-scratch"
        animation={false}
      >
        <Modal.Body>
          <div
            className={type === 'package' ? 'scratch-content my-ml-15px-unset' : 'scratch-content'}
          >
            {/*{step !== STEP.SHOW_W_CHANCE_RESULT && (
              <div
                className="skip-quantity-custom"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: step === STEP.SHOW_RESULT ? '120px' : '20px',
                }}
              >
                <div
                  className="quantity-custom-modal"
                  style={{
                    visibility:
                      step === STEP.SHOW_W_CHANCE_TICKET ||
                      step === STEP.SHOW_W_CHANCE_ANIMATION_1 ||
                      step === STEP.SHOW_W_CHANCE_ANIMATION_2
                        ? 'hidden'
                        : 'visible',
                  }}
                >
                  <span>
                    {formatCountNumber(counter + 1) <= formatCountNumber(prizesData.length)
                      ? formatCountNumber(counter + 1)
                      : formatCountNumber(prizesData.length)}{' '}
                    / {formatCountNumber(prizesData.length)}
                  </span>
                </div>
                <div className="skip-btn-custom-modal">
                  <ButtonSkipCustom onClick={() => handleMoveResult(orderId)} />
                </div>
              </div>
            )}*/}

            <div
              onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
              className={
                (step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData)) ||
                step === STEP.SHOW_RESULT ||
                step === STEP.SHOW_W_CHANCE_ANIMATION_2
                  ? 'wrap_scratch_item_scale height-unset width-custom'
                  : step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData)
                  ? 'wrap_scratch_item_scale width-custom-no-data'
                  : 'wrap_scratch_item_scale'
              }
            >
              <div
                style={{
                  visibility: step === STEP.OPEN_MODAL ? 'visible' : 'hidden',
                  opacity: step === STEP.OPEN_MODAL ? 1 : 0,
                  transition: 'all 0.1s linear',
                  position: 'relative',
                }}
              >
                <img src={ScratchScale} className="scratch-item-scale" />
                <div className="wrap_scratch_item_scale_bg" />
              </div>
              {/* {(step === STEP.SCRATCH || step === STEP.SHOW_SCRATCH) && ( */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  display: step === STEP.SCRATCH || step === STEP.SHOW_SCRATCH ? 'block' : 'none',
                  opacity: step === STEP.SCRATCH || step === STEP.SHOW_SCRATCH ? 1 : 0,
                  zIndex: 7,
                }}
              >
                <img
                  src={convertRankNameToAnimation(prizesData[counter]?.rank_name)}
                  className="scratch-item-scale"
                />
              </div>

              {/* {step === STEP.SHOW_RESULT && ( */}
              <div
                id="result-scratch"
                style={{
                  // position: 'absolute',
                  // top: '-20px',
                  display: step === STEP.SHOW_RESULT ? 'block' : 'none',
                  opacity: step === STEP.SHOW_RESULT ? 1 : 0,
                  // transition: 'all 0.1s linear',
                  zIndex: 8,
                }}
              >
                {prizesData[counter]?.is_new && <img src={NewTag} id="new-tag" />}
                <img src={prizesData[counter]?.image?.image_url} id="scratch-item-prize" />
                <div id="prize-name-custom-name" style={{ flexDirection: 'column' }}>
                  <div className="prize-name__custom">
                    {prizesData[counter]?.custom_name
                      ? prizesData[counter]?.custom_name
                      : prizesData[counter]?.rank_name + '賞'}
                  </div>
                  <div className="prize-name__title">
                    {prizesData[counter]?.custom_name
                      ? prizesData[counter]?.custom_name
                      : prizesData[counter]?.rank_name}{' '}
                    - {prizesData[counter]?.number_order} {prizesData[counter]?.title}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <MediaQuery minWidth={BREAK_POINT + 1}>
                    <GETResultPC />
                  </MediaQuery>
                  <MediaQuery maxWidth={BREAK_POINT}>
                    <GETResultSP />
                  </MediaQuery>
                  <div className="btn-next">
                    <img src={NextButton} />
                  </div>
                </div>
              </div>

              <div>
                <img
                  src={WChanceTicket}
                  className="scratch-item-scale"
                  style={{
                    position: 'absolute',
                    top: '0',
                    visibility: step === STEP.SHOW_W_CHANCE_TICKET ? 'visible' : 'hidden',
                    opacity: step === STEP.SHOW_W_CHANCE_TICKET ? 1 : 0,
                    zIndex: 9,
                  }}
                />
                <div
                  className="wrap_scratch_item_scale_bg"
                  style={{
                    display: step === STEP.SHOW_W_CHANCE_TICKET ? 'block' : 'none',
                  }}
                />
              </div>

              {/* {step === STEP.SHOW_W_CHANCE_ANIMATION_1 && ( */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  display: step === STEP.SHOW_W_CHANCE_ANIMATION_1 ? 'block' : 'none',
                  zIndex: 10,
                }}
              >
                <img
                  src={animations.image_on_challenge ? animations.image_on_challenge[0] : ''}
                  className="scratch-item-scale"
                />
              </div>
              {/* {step === STEP.SHOW_W_CHANCE_ANIMATION_2 && ( */}
              <div
                className="w-chance-animation-2"
                style={{
                  visibility: step === STEP.SHOW_W_CHANCE_ANIMATION_2 ? 'visible' : 'hidden',
                }}
              >
                <img
                  src={animations.image_on_challenge ? animations.image_on_challenge[1] : ''}
                  className="scratch-item-scale scratch-item-scale-custom"
                  style={{
                    backgroundImage: `url(${wChangeAnimation2})`,
                  }}
                />
                <div className="wrap_scratch_item_scale_bg wrap_scratch_item_scale_bg-custom" />
              </div>
              <div
                className="w-chance-ON"
                style={{
                  visibility: step === STEP.SHOW_W_CHANCE_ANIMATION_2 ? 'visible' : 'hidden',
                }}
              >
                <img src={WChanceON} style={{ width: '100%' }} alt="" />
              </div>
              {step === STEP.SHOW_W_CHANCE_TICKET || step === STEP.SHOW_W_CHANCE_ANIMATION_1 ? (
                <div className="wrap_scratch_item_scale_footer">
                  <span>当たりが出れば＋ON賞GET！</span>
                </div>
              ) : (
                <></>
              )}
              {/* {step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData) && ( */}
              <div>
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    visibility:
                      step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData)
                        ? 'visible'
                        : 'hidden',
                    opacity: step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData) ? 1 : 0,
                    transition: 'all ease-in-out',
                    zIndex: 12,
                  }}
                >
                  <img src={WChanceMiss} className="scratch-item-scale" />
                  <div className="wrap_scratch_item_scale_bg" />
                </div>
                <div
                  className="btn-next"
                  style={{
                    visibility:
                      step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData)
                        ? 'visible'
                        : 'hidden',
                    opacity: step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData) ? 1 : 0,
                    textAlign: 'end',
                    marginTop: '21px',
                  }}
                >
                  <img
                    src={NextButton}
                    style={{ width: '163px', height: '54px' }}
                    onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
                  />
                </div>
              </div>
              {/* {step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData) && ( */}
              <div
                id="result-scratch"
                style={{
                  // position: 'absolute',
                  // top: '-100px',
                  visibility:
                    step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData)
                      ? 'visible'
                      : 'hidden',
                  opacity: step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData) ? 1 : 0,
                  transition: 'all ease-in-out',
                }}
              >
                <div id="w-chance-result">
                  <img src={Congratulations} id="congratulations" />
                  <img src={wChanceData?.image?.image_url} id="scratch-item-w-chance" />
                  <div className="w-chance-result-footer-custom">
                    <div>
                      <img src={WChanceONfooter} alt="" />
                    </div>
                    <span className="description">{wChanceData?.title}</span>
                  </div>
                </div>
                <div className="btn-next">
                  <img
                    src={NextButton}
                    onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
                  />
                </div>
              </div>
            </div>

            {[
              STEP.OPEN_MODAL,
              STEP.SHOW_SCRATCH,
              STEP.SCRATCH,
              STEP.SHOW_W_CHANCE_TICKET,
              STEP.SHOW_W_CHANCE_ANIMATION_1,
            ].includes(step) && (
              <div
                className="next-button"
                onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
              >
                タップしてクジをめくる
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
