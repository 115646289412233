// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import TopHeader from '../../components/common/topHeader';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import { MyCollectionApis } from '../../services/myCollection';
import useMessage from '../../components/common/toast/UseMessage';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { convertRankName } from '../../utils/convertSaleStatus';
import { listsErrorNotShowToast } from '../../constants';

function MyCollectionPrizeDetail(props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { lotteryId, prizeId } = useParams();
  const history = useHistory();
  const { openMessageError } = useMessage();

  const handleMovePage = (id) => {
    history.push(`/order/history/${id}`);
  };

  const [collectionPrize, setCollectionPrize] = useState();

  const getCollectionPrize = async () => {
    dispatch(showLoading());
    try {
      const res = await MyCollectionApis.getCollectionPrize({
        lottery_event_id: lotteryId,
        prize_id: prizeId,
      });
      const { data } = res;
      setCollectionPrize(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getCollectionPrize();
  }, []);
  return (
    <>
      {collectionPrize && (
        <>
          <TopHeader
            title={`${
              collectionPrize.custom_name
                ? collectionPrize.custom_name
                : convertRankName(collectionPrize.rank_name)
            }-${collectionPrize.number_order}${` `}${collectionPrize.title}`}
          />
          <div className="my-collection-prize-detail">
            <div className="main-image">
              <img src={collectionPrize.image.image_url} alt="img" />
            </div>
            <div className="description-image">
              <div className="bg-pink description-label">
                {collectionPrize.custom_name
                  ? collectionPrize.custom_name
                  : convertRankName(collectionPrize.rank_name) + '賞'}
              </div>{' '}
              <div className="description-content bottom">
                {collectionPrize.rank_title}{' '}
                {collectionPrize.custom_name
                  ? collectionPrize.custom_name
                  : convertRankName(collectionPrize.rank_name)}
                -{collectionPrize.number_order} {collectionPrize.title}
              </div>
              <div className="bg-green description-label">当せん個数</div>
              <div className="description-content">
                {collectionPrize.total}個 （ 破棄数：
                {collectionPrize.total_discard}個 ）
              </div>
            </div>

            {collectionPrize.orders && (
              <div className="my-prize-detail-history">
                <p className="title">{t('titleTableHistoryMyCollection')}</p>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{t('titleDateTableHistoryMyCollection')}</th>
                      <th>{t('titlePurchaseIDTableHistoryMyCollection')}</th>
                      <th>{t('titleButtonTableHistoryMyCollection')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collectionPrize.orders.map((order, index) => {
                      const { id } = order;
                      return (
                        <tr key={index}>
                          <td>{order.created_at}</td>
                          <td>{order.purchase_id}</td>
                          <td>
                            <span
                              style={{ color: '#1d9ded', textDecoration: 'underline' }}
                              className="cursor-pointer"
                              onClick={() => handleMovePage(id)}
                            >
                              購入詳細を見る <AiOutlineRight />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                {collectionPrize.orders && (
                  <div className="list-history-purchase-sp">
                    {collectionPrize.orders.map((order, index) => {
                      const { id } = order;
                      return (
                        <div
                          className="d-flex align-items-center list-history-content"
                          key={index}
                          style={{ color: '#1D9DED' }}
                          onClick={() => handleMovePage(id)}
                        >
                          <div className="col-11">
                            <div>
                              <p className="title">
                                {t('titleDateTableHistoryMyCollection')}
                                <span className="created-at">{order.created_at}</span>
                              </p>
                              <p className="title mb-0">
                                {t('titlePurchaseIDTableHistoryMyCollection')}
                                <span className="purchase-id">{order.purchase_id}</span>
                              </p>
                            </div>
                          </div>
                          <div className="col-1 text-center cursor-pointer">
                            <AiOutlineRight onClick={() => handleMovePage(id)} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <ScrollButton />
    </>
  );
}

export default MyCollectionPrizeDetail;
