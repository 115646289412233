// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useHistory } from 'react-router-dom';
import { ON_CHALLENGE } from '../../../../constants';
import { convertRankName } from '../../../../utils/convertSaleStatus';

export default function PrizeItem(props) {
  const { data, type, totalPrize, onClick } = props;
  const history = useHistory();
  const pathLotteryTrial = '/result/trial';
  const pathSite = history.location.pathname;

  return (
    <>
      {data && (
        <div className="card-prize-sp card" onClick={onClick}>
          <div className="card-img-prize">
            <div>
              <img
                src={
                  pathLotteryTrial === pathSite
                    ? data?.image?.thumbnail_url
                    : data?.image[0].thumbnail_url
                }
                alt={data.title}
                className="prizeItemImg"
              />
            </div>
          </div>
          <div className="card-body p-0">
            <div className="name">{`${
              data.custom_name ? data.custom_name : convertRankName(data.rank_name)
            } - ${data.number_order} ${data.title}`}</div>
            <div className="quantity">
              <div>
                <span style={{ letterSpacing: 1, fontSize: 10 }}>
                  {type === ON_CHALLENGE && !data.quantity ? 'ハズレ個数' : '当せん個数'}
                </span>
                <span
                  style={{
                    marginLeft: '2.4vw',
                    fontWeight: 'bold',
                    fontSize: 12,
                    lineHeight: '20px',
                  }}
                >
                  {type === ON_CHALLENGE && !data.quantity ? totalPrize : data.quantity} 個
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
