// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { BUY_LOTS, RETAIL, USE_ADULT_PURCHASE } from '../../../constants';
import { convertRankName } from '../../../utils/convertSaleStatus';
import { countPrice } from '../../../utils/countPrice';

function SelectQuantity(props) {
  const { t } = useTranslation();
  const { data, getPurchaseMethod, getNumberOfLottery, search } = props;
  const lotteryEvent = data?.lottery_event;

  const calculatorShipQuantity =
    lotteryEvent?.lottery_per_package <= 1 ? 1 : lotteryEvent?.lottery_per_package - 1;
  const maxShipQuantity = lotteryEvent?.adult_purchase_flag ? calculatorShipQuantity : 100;

  var rows = [];
  for (var item = 1; item <= maxShipQuantity; item++) {
    rows.push(
      <option value={item} key={item}>
        {item}
      </option>,
    );
  }

  const [purchaseMethod, setPurchaseMethod] = useState(
    Number(search?.adult_purchase) ? Number(search?.adult_purchase) : 0,
  );

  const [numberOfLottery, setNumberOfLottery] = useState(
    Number(search?.quantity) ? Number(search?.quantity) : 1,
  );

  const handleChangeMethod = (e) => {
    setPurchaseMethod(Number(e.target.value));
    getPurchaseMethod(Number(e.target.value));
  };

  const handleSetNumberOfLottery = (e) => {
    setNumberOfLottery(Number(e.target.value));
    getNumberOfLottery(Number(e.target.value));
  };

  const history = useHistory();
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;

  useEffect(() => {
    if (search?.quantity) {
      setNumberOfLottery(Number(search?.quantity));
      getNumberOfLottery(Number(search?.quantity));
    }
  }, []);

  return (
    <>
      <div className="select-quantity_to_buy my-box-purchase">
        <div className="title">
          <strong className="title-inbox">{t('titleQuantitySelect')}</strong>
        </div>

        <div className="retail d-flex align-items-center">
          <div className="form-check px-0" onChange={(e) => handleChangeMethod(e)}>
            <input
              type="radio"
              id="retail"
              name="purchase"
              value={RETAIL}
              defaultChecked={purchaseMethod === RETAIL}
            />
            <label htmlFor="retail">{t('titleSpecifyTheNumber')}</label>
          </div>
          <div className={purchaseMethod === RETAIL ? 'my-select' : 'my-disable my-select'}>
            <select
              name="quantity"
              id="quantity"
              disabled={purchaseMethod === RETAIL ? false : true}
              onChange={(e) => handleSetNumberOfLottery(e)}
              defaultValue={Number(search?.quantity) ? Number(search?.quantity) : numberOfLottery}
            >
              {rows}
            </select>
            <div className="my-icon-select">
              <FiChevronDown />
            </div>
          </div>
          <p className="mb-0">数</p>
        </div>

        {lotteryEvent?.adult_purchase_flag === USE_ADULT_PURCHASE &&
          (lotteryEvent?.package_quantity > 0 || lotteryEvent?.package_quantity === null) && (
            <div className="buy-lots">
              <div className="form-check px-0" onChange={(e) => handleChangeMethod(e)}>
                <input
                  type="radio"
                  id="buy-lots"
                  name="purchase"
                  value={BUY_LOTS}
                  defaultChecked={purchaseMethod === BUY_LOTS}
                />
                <label htmlFor="buy-lots">
                  {t('textOptionBuyLots').replace('param', lotteryEvent?.lottery_per_package)}
                </label>
                {lotteryEvent?.prize_rank_name && lotteryEvent?.prize_quantity && (
                  <span className="note-rank-quantity">
                    {t('descriptionBuyLots')
                      .replace('param', convertRankName(lotteryEvent?.prize_rank_name))
                      .replace('count', lotteryEvent?.prize_quantity)}
                  </span>
                )}
              </div>
            </div>
          )}
        <div className="line" />

        <div className="price-select-quantity">
          <p className="mb-0">
            小計{' '}
            {pathLotteryTrial === pathSite ? (
              0
            ) : (
              <strong>
                {purchaseMethod === RETAIL
                  ? countPrice(lotteryEvent?.price_included_tax, numberOfLottery).toLocaleString(
                      'ja-JP',
                    )
                  : countPrice(
                      lotteryEvent?.price_included_tax,
                      lotteryEvent?.lottery_per_package
                    ).toLocaleString('ja-JP')}
              </strong>
            )}{' '}
            円<small>(税込)</small>
          </p>
        </div>
      </div>
    </>
  );
}

export default SelectQuantity;
