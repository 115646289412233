// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../../common/button';
import FacebookLogin from 'react-facebook-login';
import { FaFacebookF } from 'react-icons/fa';
import GoogleLogin from 'react-google-login';
import { AuthApis } from '../../../services/auth';
import { useAppDispatch } from '../../../store';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import useMessage from '../../common/toast/UseMessage';
import { listsErrorNotShowToast } from '../../../constants';

function SNSSocial(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const { image, name, status, social, callbackFunction } = props;

  const responseSocial = async (response) => {
    const { accessToken } = response;
    if (accessToken) {
      linkAccount(accessToken);
    }
  };

  const linkAccount = async (accessToken) => {
    dispatch(showLoading());
    try {
      const formData = {
        provider: social,
        access_token: accessToken,
      };
      const res = await AuthApis.linkAccount(formData);
      openMessage({ message: res?.data?.success, variant: 'success' });
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      callbackFunction();
      dispatch(hideLoading());
    }
  };
  const unlinkAccount = async () => {
    dispatch(showLoading());
    try {
      const res = await AuthApis.unLinkAccount(social);
      openMessage({ message: res?.data?.success, variant: 'success' });
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      callbackFunction();
      dispatch(hideLoading());
    }
  };
  const handleLoginTwitter = async () => {
    dispatch(showLoading());
    try {
      const res = await AuthApis.loginTwitter();
      const { login_url } = res.data;
      if (login_url) {
        window.location = login_url;
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <div className="sns-social-item">
      <div className="sns-management-status">
        <img src={image} alt="img" className="sns-management-image" />
        <div className="sns-management-title">
          <div className="sns-name">{name}</div>
          <div className="sns-status sns-management-status">
            {t('snsManagerFormat')}
            <span style={status ? { color: '#F55076' } : {}}>{status ? '連携中' : '未設定'}</span>
          </div>
          <div className="sns-status sns-management-status-sp">
            {t('snsManagerFormatSP')}
            <span style={status ? { color: '#F55076' } : {}}>{status ? '連携中' : '未設定'}</span>
          </div>
        </div>
      </div>
      <div className="sns-management-action">
        <div className="btn-sns-setting">
          <TButton
            title={status ? '解除する' : '連携する'}
            className={status ? 'primary' : 'primary-yellow'}
            onClick={unlinkAccount}
          />
          <div className="btn-social-default">
            {social === 'twitter' && !status && (
              <TButton
                title={status ? '解除する' : '連携する'}
                className={status ? 'primary' : 'primary-yellow'}
                onClick={handleLoginTwitter}
              />
            )}
            {social === 'facebook' && !status && (
              <FacebookLogin
                appId={process.env.APP_ID_FACEBOOK}
                fields="name,email,picture"
                callback={(res) => responseSocial(res)}
                textButton={t('btnFacebook')}
                cssClass="btnFacebook-sns"
                icon={<FaFacebookF className="icon-social" />}
                isMobile={false}
              />
            )}
            {social === 'google' && !status && (
              <GoogleLogin
                clientId={process.env.CLIENT_ID_GOOGLE}
                onSuccess={(res) => responseSocial(res)}
                cookiePolicy={'single_host_origin'}
                className="btnGoogle"
                icon={false}
              ></GoogleLogin>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SNSSocial;
