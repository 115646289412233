// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { MyCollectionApis } from '../../../../services/myCollection';
import { useAppDispatch } from '../../../../store';
import { showLoading, hideLoading } from '../../../../store/features/loadingPage';
import TButton from '../../../common/button';
import useMessage from '../../../common/toast/UseMessage';
import { FiChevronDown } from 'react-icons/fi';

import './index.scss';
import { convertRankName } from '../../../../utils/convertSaleStatus';
import { listsErrorNotShowToast } from '../../../../constants';
import { UserInfoApis } from '../../../../services/userInfo';

const LotteryCard = (props) => {
  const { data, canDiscard, refundPoint = 0 } = props;
  const { lotteryId } = useParams();

  const [discardNumber, setNumber] = useState(0);
  const [isShowModal, toggleShowModal] = useState(false);

  const { openMessageError } = useMessage();

  const [isDiscardPrizeSuccess, setSuccess] = useState(false);

  const dispatch = useAppDispatch();

  const renderSelect = (maxValue, minValue = 0) => {
    const options = [];
    for (let i = minValue; i <= maxValue; i++) {
      options.push(
        <option value={i} key={i}>
          {i}
        </option>,
      );
    }
    return (
      <div className="my-select my-select-collection-detail">
        <select onChange={(e) => setNumber(parseInt(e.target.value))}>{options}</select>
        <div className="my-icon-select">
          <FiChevronDown />
        </div>
      </div>
    );
  };

  const handleDiscardPrize = async () => {
    dispatch(showLoading());
    try {
      await MyCollectionApis.discardPrize({
        lottery_event_id: parseInt(lotteryId),
        prize_id: data.prize_id,
        quantity: discardNumber,
      });
      setSuccess(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleDownloadDigitalPrize = async () => {
    dispatch(showLoading());
    try {
      const res = await MyCollectionApis.downloadDigitalPrize({
        lottery_event_id: parseInt(lotteryId),
        prize_id: data.prize_id,
      });
      const link = document.createElement('a');
      link.setAttribute('href', decodeURIComponent(res.data.signed));
      link.setAttribute('download', res.data.filename);
      document.body.appendChild(link);
      link.click();
      setSuccess(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const [point, setPoint] = useState();
  const getUserInfo = async () => {
    const formData = {
      info: 'profile',
    };
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(formData);
      const { data } = res;
      setPoint(data?.total_point);
      toggleShowModal(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleToggleShowModal = () => {
    getUserInfo();
  };

  return (
    <div className="lottery-card">
      <div className="card-lottery-title">
        {data.custom_name ? data.custom_name : convertRankName(data.rank_name)}-{data.number_order}{' '}
        {data.title}
      </div>
      <div className="card-img">
        <div>
          <img src={data?.image[0]?.thumbnail_url} />
        </div>
      </div>
      <div className="card-info">
        <div className="lottery-quantity">
          <div>
            <div style={{ fontSize: 12 }}>当せん個数</div>
            <div className="fw-bold">
              <span style={{ fontSize: 24 }}>{data.total}</span> 個
            </div>
          </div>
          {!data.is_digital && (
            <>
              <div>
                <div style={{ fontSize: 12 }}>受取数</div>
                <div className="fw-bold">
                  <span>{data.total - data.total_discard}</span> 個
                </div>
              </div>
              <div>
                <div style={{ fontSize: 12 }}>破棄数</div>
                <div style={{ fontSize: 16 }}>{renderSelect(data.total - data.total_discard)}</div>
              </div>
            </>
          )}
        </div>
        {data.is_digital ? (
          <TButton
            className="primary button-delete"
            title="ダウンロードする"
            onClick={() => handleDownloadDigitalPrize()}
          />
        ) : (
          <TButton
            className="primary button-delete"
            title="破棄する"
            onClick={() => canDiscard && discardNumber && handleToggleShowModal()}
            disabled={!canDiscard}
          />
        )}
      </div>

      <Modal
        size="lg"
        show={isShowModal}
        onHide={() => {
          setSuccess(false);
          toggleShowModal(false);
        }}
      >
        <Modal.Body>
          <div className="delete-item-popup">
            {isDiscardPrizeSuccess ? (
              <div style={{ marginTop: 140, marginBottom: 213, textAlign: 'center' }}>
                <div className="popup-title" style={{ marginBottom: 100 }}>
                  破棄が完了しました。
                </div>
                <div style={{ fontSize: 20, marginBottom: 180 }}>
                  獲得ポイント　
                  <span style={{ fontSize: 30, fontWeight: 'bold' }}>
                    {Math.floor(discardNumber * refundPoint)?.toLocaleString('ja-JP')}
                  </span>
                  <span style={{ fontWeight: 'bold' }}>Pt</span>
                </div>
                <TButton
                  title="Myコレクションへ戻る"
                  className="primary delete-popup-btn"
                  onClick={() => window.location.reload()}
                />
              </div>
            ) : (
              <>
                <div className="popup-title">この商品を本当に破棄しますか？</div>
                <div className="popup-notice">※一度破棄した商品は元には戻せません。</div>
                <div className="popup-item-name">
                  {data.custom_name ? data.custom_name : data.rank_name}-{data.number_order}{' '}
                  {data.title}
                </div>
                <div className="popup-item-img">
                  <img src={data?.image[0]?.thumbnail_url} />
                </div>
                <div style={{ fontSize: 20 }}>
                  所持クジポイント　
                  <span style={{ fontSize: 30, fontWeight: 'bold' }}>
                    {point?.toLocaleString('ja-JP')}
                  </span>
                  <span style={{ fontSize: 20, fontWeight: 'bold' }}>Pt</span>
                </div>
                <div className="popup-item-quantity">
                  <div>
                    破棄個数　<span>{discardNumber}</span>
                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>個</span>
                  </div>
                  <div style={{ marginLeft: 40 }}>
                    獲得ポイント　
                    <span>{Math.floor(discardNumber * refundPoint)?.toLocaleString('ja-JP')}</span>
                    <span style={{ fontSize: 20, fontWeight: 'bold' }}>Pt</span>
                  </div>
                </div>
                <TButton
                  title="破棄する"
                  className="primary-yellow mt-5"
                  onClick={handleDiscardPrize}
                />
                <TButton
                  title="修正する"
                  className="cancel-btn mt-4"
                  onClick={() => {
                    setSuccess(false);
                    toggleShowModal(false);
                  }}
                />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LotteryCard;
