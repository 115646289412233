// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch, useAppSelector } from '../../store';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import '../forgotPassword/styles.scss';
import TButton from '../../components/common/button';
import { AuthApis } from '../../services/auth';
import { validateCurrentPassword } from '../../validations/validatePassword';
import { validateConfirmNewEmail, validateNewEmail } from '../../validations/validateEmail';
import SendEmailSuccess from './sendEmailSuccess';

export default function ChangeEmail() {
  const { openMessageError, openMessage } = useMessage();
  const {
    auth: { userInfo },
  } = useAppSelector((state) => state);
  const emailUser = userInfo?.email;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [validationMsg, setValidationMsg] = useState({});
  const [validationMsgEmail, setValidationMsgEmail] = useState('');
  const [validationMsgPassword, setValidationMsgPassword] = useState('');
  const [isSendEmailSuccess, setSendEmailSuccess] = useState(false);

  const handleChangePassword = (event) => {
    setOldPassword(event.target.value);
    setValidationMsg('');
    setValidationMsgPassword('');
  };

  const handleChangeEmail = (event) => {
    setNewEmail(event.target.value);
    setValidationMsg('');
    setValidationMsgEmail('');
  };

  const validateAll = () => {
    const error = {
      oldPassword: '',
      newEmail: '',
      confirmNewEmail: '',
    };
    error.oldPassword = validateCurrentPassword(oldPassword);
    error.newEmail = validateNewEmail(newEmail.trim());
    error.confirmNewEmail = validateConfirmNewEmail(newEmail.trim(), confirmNewEmail.trim());
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i++) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    const formData = {
      old_email: emailUser,
      password: oldPassword,
      email: newEmail,
      email_confirmation: confirmNewEmail,
    };
    try {
      const res = await AuthApis.changeEmail(formData);
      const { data } = res;
      const messageSuccess = data.message;
      openMessage({ message: `${messageSuccess}`, variant: 'success' });
      setSendEmailSuccess(true);
    } catch (error) {
      if (error?.response?.status === 422) {
        setValidationMsgEmail(error.response.data.message);
      } else if (error?.response?.status === 400) {
        setValidationMsgPassword(error.response.data.message);
      } else {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div>
      <TopHeader title={t('headerChangeEmail')} />
      {isSendEmailSuccess ? (
        <SendEmailSuccess />
      ) : (
        <form
          className="main-content forgot-password"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-forgot-password">
            <div className="title-change-email">{t('titleChangeEmail')}</div>
            <div className="note-change-email">
              現在登録しているメールアドレスに、認証コードを送信します。
            </div>
            <div className="txt-change-email">{t('txtCurrentEmail')}</div>
            <div className="space-change-email">
              <div className="input-group">
                <input
                  type="password"
                  maxLength="18"
                  className="form-control border-input"
                  value={oldPassword}
                  onChange={handleChangePassword}
                ></input>
              </div>
              {validationMsg.oldPassword && (
                <div className="txt_error">{validationMsg.oldPassword}</div>
              )}
              {validationMsgPassword && <div className="txt_error">{validationMsgPassword}</div>}
            </div>
            <div className="txt-change-email">{t('txtNewEmail')}</div>
            <div className="space-change-email">
              <div className="input-group">
                <input
                  type="text"
                  maxLength="255"
                  className="form-control border-input"
                  value={newEmail}
                  onChange={handleChangeEmail}
                ></input>
              </div>
              {validationMsg.newEmail && <div className="txt_error">{validationMsg.newEmail}</div>}
              {validationMsgEmail && <div className="txt_error">{validationMsgEmail}</div>}
            </div>
            <div className="txt-change-email">{t('txtConfirmNewEmail')}</div>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  maxLength="255"
                  className="form-control border-input"
                  value={confirmNewEmail}
                  onChange={(event) => setConfirmNewEmail(event.target.value)}
                ></input>
              </div>
              {validationMsg.confirmNewEmail && (
                <div className="txt_error">{validationMsg.confirmNewEmail}</div>
              )}
            </div>
          </div>
          <div className="text-center btn-change-email">
            <TButton
              title={t('buttonChangeEmail')}
              className="btn primary-yellow change-email"
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  );
}
