// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import { useEffect } from 'react';

const createMeta = (attribute, key, value) => {
  var meta = document.createElement('meta');
  meta.setAttribute(attribute, key);
  meta.content = value;
  document.getElementsByTagName('head')[0].appendChild(meta);
};

export default (params) => {
  const { title, description, keyword, twitterCard, twitterSite, url, image } = params;

  useEffect(() => {
    if (!(title && description && keyword && twitterCard && twitterSite && url && image)) return;

    document.title = title + '|ベスくじ｜限定グッズが当たるオンラインくじ';

    document
      .querySelector("meta[property='og:title']")
      .setAttribute('content', title + '|ベスくじ｜限定グッズが当たるオンラインくじ');

    createMeta('property', 'og:url', url);

    document.querySelector("meta[property='og:description']").setAttribute('content', description);

    document.querySelector("meta[property='og:image']").setAttribute('content', image);

    document.querySelector("meta[name='description']").setAttribute('content', description);

    document.querySelector("meta[name='keyword']").setAttribute('content', keyword);

    createMeta('name', 'twitter:card', twitterCard);

    createMeta('name', 'twitter:site', twitterSite);

    return () => {
      document.title = 'ベスくじ｜限定グッズが当たるオンラインくじ';

      document
        .querySelector("meta[property='og:title']")
        .setAttribute('content', 'ベスくじ｜限定グッズが当たるオンラインくじ');

      document.querySelector("meta[property='og:url']").remove();

      document.querySelector("meta[property='og:description']").setAttribute('content', 'ベスくじ');

      document
        .querySelector("meta[property='og:image']")
        .setAttribute('content', window.location.origin + '/imgs/OGP.png');

      document
        .querySelector("meta[name='description']")
        .setAttribute(
          'content',
          `スマホ・PCから簡単に遊べる、 オンラインくじ「ベスくじ」ここでしか手に入らない限定グッズが盛りだくさん！`,
        );

      document
        .querySelector("meta[name='keyword']")
        .setAttribute('content', `ベスくじ,オンラインスクラッチくじ, オンラインくじ,アイドル`);

      document.querySelector("meta[name='twitter:card']").remove();
      document.querySelector("meta[name='twitter:site']").remove();
    };
  }, [params]);
};
