// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Spinner from './components/common/spinner/Spinner';
import Footer from './components/common/footer';
import Header from './components/common/header';
import '../assets/scss/index.scss';
import LotteryDetail from './pages/lotteryDetail';
import Register from './pages/register';
import Login from './pages/login';
import Top from './pages/top';
import ConfirmLoginSocial from './pages/login/confirmLoginSocial';
import PurchaseLottery from './pages/purchaseLottery';
import ListNews from './pages/listNews';
import PrivateRoute from './PrivateRoute';
import NewsDetail from './pages/newsDetail';
import MyCollection from './pages/myCollection';
import MyCollectionPrizeDetail from './pages/myCollectionPrizeDetail';
import MyPage from './pages/myPage';
import EditAddress from './pages/editAddress';
import MyCollectionLotteryDetail from './pages/myCollectionLotteryDetail';
import Verify from './components/pages/Verify';
import TwitterCallbackPage from './components/pages/TwitterCallbackPage';
import DeleteAccount from './pages/deleteAccount';
import ErrorScreen from './components/common/error';
import ScrollToTop from './components/ScrollToTop';
import ForgotPassword from './pages/forgotPassword';
import ToastMessage from './components/common/toast/ToastMessage';
import ResetPassword from './pages/resetPassword';
import ListLottery from './pages/listLottery';
import PurchaseHistory from './pages/purchaseHistory';
import Scratch from './pages/scratch';
import ScratchResult from './pages/scratchResult';
import PurchaseHistoryDetail from './pages/purchaseHistoryDetail';
import VerifyFail from './components/pages/VerifyFail';
import ChangeEmail from './pages/changeEmail';
import ChangePassword from './pages/changePassword';
import ContactUs from './pages/contactUs';
import SNSManagement from './pages/snsManagement';
import VerifyNewEmail from './pages/changeEmail/verifyNewEmail';
import Terms from './components/pages/Terms';
import CompanyInfo from './components/pages/CompanyInfo';
import Law from './components/pages/Law';
import Maintain from './components/pages/Maintain';
import UserGuide from './pages/userGuide';
import LotteryTrial from './pages/LotteryTrial';
import DeleteAccountSuccess from './pages/deleteAccount/DeleteAccountSuccess';
import SystemPage from './pages/system';
import ContactSuccess from './pages/contactUs/ContactSuccess';
import PointHistory from './pages/pointHistory';
import LandingPage from './pages/landingPage';
import Questions from './components/pages/Questions';

export const App = () => {
  const history = useHistory();
  const pathSystem = '/lp';
  const pathSite = history.location.pathname;
  const [meta, setMeta] = useState();
  let patternLottery = /\/lottery\/*\d/;
  let patternNews = /\/news\/*\d/;

  return (
    <>
      {pathSystem === pathSite ? (
        <>
          <Switch>
            <>
              <div className="content">
                <ScrollToTop />
                <Switch>
                  <Route path="/lp" component={SystemPage} />
                </Switch>
              </div>
            </>
          </Switch>
        </>
      ) : (
        <>
          <Spinner />
          <ToastMessage />
          <Switch>
            <>
              <Header />
              <div className="content">
                <ScrollToTop />
                <Switch>
                  <Route path="/trial" component={LotteryTrial} />
                  <Route path="/purchase/trial" component={PurchaseLottery} />
                  <Route path="/scratch/trial/:type" component={Scratch} />
                  <Route path="/result/trial" component={ScratchResult} />
                  <Route path="/lottery/:id" component={LotteryDetail} />
                  <Route path="/lottery" component={ListLottery} />
                  <Route path="/lp1" component={LandingPage} />
                  <Route path="/lp2" component={LandingPage} />
                  <Route path="/scratch/:type" component={Scratch} />
                  <Route path="/result" component={ScratchResult} />
                  <PrivateRoute path="/purchase/:id/edit-address" component={EditAddress} />
                  <PrivateRoute path="/purchase/:id" component={PurchaseLottery} />
                  <Route path="/login" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/register-social" component={ConfirmLoginSocial} />
                  <Route path="/news/:id" component={NewsDetail} />
                  <Route path="/news" component={ListNews} />
                  <Route path="/edit-address" component={EditAddress} />
                  <PrivateRoute
                    path="/order/history/:orderId/edit-address"
                    component={EditAddress}
                  />
                  <PrivateRoute path="/order/history/:id" component={PurchaseHistoryDetail} />
                  <PrivateRoute path="/order/history" component={PurchaseHistory} />
                  <Route path="/change-password" component={ChangePassword} />
                  <Route path="/contact/success" component={ContactSuccess} />
                  <Route path="/contact" component={ContactUs} />
                  <Route path="/delete-account/success" component={DeleteAccountSuccess} />
                  <PrivateRoute path="/delete-account" component={DeleteAccount} />
                  <PrivateRoute path="/change-email/success" component={VerifyNewEmail} />
                  <PrivateRoute path="/change-email" component={ChangeEmail} />
                  <PrivateRoute path="/change-password" component={ChangePassword} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/error/:errorId" component={ErrorScreen} />
                  <PrivateRoute
                    path="/my-collection/:lotteryId/prize/:prizeId"
                    component={MyCollectionPrizeDetail}
                  />
                  <PrivateRoute path="/point-history" component={PointHistory} />
                  <PrivateRoute
                    path="/my-collection/:lotteryId"
                    component={MyCollectionLotteryDetail}
                  />
                  <PrivateRoute path="/my-collection" component={MyCollection} />
                  <PrivateRoute path="/my-page" component={MyPage} />
                  <Route path="/verify/invalid" component={VerifyFail} />
                  <Route path="/verify" component={Verify} />
                  <Route path="/twitter" component={TwitterCallbackPage} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/frequently-question" component={Questions} />
                  <Route path="/about-us" component={CompanyInfo} />
                  <Route path="/commercial-transaction-law" component={Law} />
                  <Route path="/maintain" component={Maintain} />
                  <Route path="/sns-management" component={SNSManagement} />
                  <Route path="/guide" component={UserGuide} />
                  <Route exact path="/" component={Top} />
                  <Redirect to="/error/404" />
                </Switch>
              </div>
              <Footer />
            </>
          </Switch>
        </>
      )}
    </>
  );
};
