// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

export const getColorStatus = (status) => {
  if (status === 3) {
    return 'status-delivered';
  } else if (status === 2 || status === 1) {
    return 'status-prepare-deliver';
  } else if (status === 100) {
    return 'status-digital-prize';
  }
};
