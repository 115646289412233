// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch, useAppSelector } from '../../store';
import { AuthApis } from '../../services/auth';
import TButton from '../../components/common/button';
import useMessage from '../../components/common/toast/UseMessage';
import { validateCode } from '../../validations/validatePassword';
import ModalSuccess from './modalSuccess';
import { listsErrorNotShowToast } from '../../constants';

export default function SendEmailSuccess() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [code, setCode] = useState('');
  const [validationMsg, setValidationMsg] = useState('');
  const {
    auth: { userInfo },
  } = useAppSelector((state) => state);
  const userId = userInfo?.id;
  const { openMessageError } = useMessage();
  const [modalShow, setModalShow] = useState(false);

  const validateAll = () => {
    setValidationMsg(validateCode(code.trim()));
    if (validateCode(code.trim()) !== '') return false;
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    const formData = {
      user_id: userId,
      code: Number(code),
    };
    try {
      await AuthApis.confirmCode(formData);
      setModalShow(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <div className="main-content">
        <form
          className="forgot-password"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-forgot-password">
            <div className="title-change-email">{t('titleCodeEmail')}</div>
            <div className="note-change-email">
              現在登録しているメールに届いた認証コードを入力してください。
              <br />
              新しいメールアドレスに確認メールを送信いたします。
            </div>
            <div className="txt-change-email ">{t('txtCode')} </div>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  autoFocus
                  className="form-control border-input"
                  maxLength="6"
                  value={code}
                  onChange={(event) => setCode(event.target.value.replace(/\D/g, ''))}
                />
              </div>
              {validationMsg && <div className="txt_error">{validationMsg}</div>}
            </div>
          </div>

          <div className="text-center btn-code">
            <TButton
              title={t('btnSubmitCode')}
              className="btn primary-yellow change-email"
              type="submit"
            />
          </div>

          <div className="info-code">
            <span>※認証コードが届いてない場合は前の画面からやり直してください。</span>
          </div>
        </form>
      </div>
      <ModalSuccess show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
