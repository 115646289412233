// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import rankA from '../../../../assets/imgs/lotteryDetail/rankA.png';
import rank from '../../../../assets/imgs/lotteryDetail/rank.png';
import { useTranslation } from 'react-i18next';
import { setBackGroundLineUp } from '../../../utils/setBackGroundLineUp';
import { QUANTITY_PRIZE, RANK_A } from '../../../constants';
import { convertRankName } from '../../../utils/convertSaleStatus';

function LineUpHeader(props) {
  const { t } = useTranslation();
  const { prizeGroup, quantityPrize } = props;

  return (
    <div className="my-line-up-header" style={{ backgroundColor: prizeGroup.color }}>
      <div className="line-up-line" />
      <div className="my-line-up-content">
        <div className="my-rank-name">
          <div className="logo-rank-name">
            <div className={prizeGroup.rank_name === RANK_A ? 'rank-name rank-a' : 'rank-name'}>
              {convertRankName(prizeGroup.rank_name)}
              <span>賞</span>
            </div>
            <img src={prizeGroup.rank_name === RANK_A ? rankA : rank} alt="logo" />
          </div>

          <div className="wrap-title-prize">
            <div className="title-prize">{prizeGroup.title}</div>
            <div className="tag-quantity-content" style={{ color: prizeGroup.color }}>
              {t('quantityPrize').replace('param', prizeGroup.quantity_prize)}
            </div>
          </div>
        </div>

        <div className="line-up-win-rate" style={{ backgroundColor: prizeGroup.color }}>
          <div className="win-rate-first">
            <div className="text">{t('winRate')}</div>
            <div className="win-rate-value">
              {parseFloat(prizeGroup.win_rate).toFixed(2)}
              <span>%</span>
            </div>
          </div>
          {quantityPrize === QUANTITY_PRIZE.ACTIVE && (
            <>
              <div className="border-left"></div>
              <div className="win-rate-second">
                {prizeGroup.total_quantity > 0 ? (
                  <>
                    <div className="text">{t('winRate/100')}</div>
                    <div className="win-rate-value">
                      <span>
                        <strong>{prizeGroup.total_quantity}</strong>/
                        {prizeGroup.total_begin_quantity}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="win-rate-sold-out">SOLD OUT</div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="line-up-line" />
    </div>
  );
}

export default LineUpHeader;
