// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
export default function InformationLottery(props) {
  const { dataOrders, data } = props;
  const { t } = useTranslation();
  const showTrackingNumber = (data, dataOrders) => {
    if (!data && !dataOrders) return;
    if (data.total_digital == data.total_prize) {
      return <td>お届けはありません。</td>;
    } else {
      if (dataOrders?.tracking_ship_number) {
        return (
          <td>
            {dataOrders?.shippment_company_info}({dataOrders?.tracking_ship_number})
          </td>
        );
      } else {
        return <td>※ 発送準備が整い次第表示されます。</td>;
      }
    }
  };
  return (
    <div>
      <Table bordered>
        <tbody>
          <tr>
            <th>{t('textNameLottery')}</th>
            <td>{dataOrders?.name_lottery}</td>
          </tr>
          <tr>
            <th>{t('titlePurchaseIDTableHistoryMyCollection')}</th>
            <td>{dataOrders?.purchase_id}</td>
          </tr>
          <tr>
            <th>{t('textTrackingNumber')}</th>
            {showTrackingNumber(data, dataOrders)}
          </tr>
          <tr>
            <th>{t('textTimePurchase')}</th>
            <td>{dataOrders?.created_at}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
