// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NotifyNews from '../../notifyNews';
import './headerPC.scss';

export default function HeaderPC(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    gotoLogin,
    isLogin,
    handleLogout,
    gotoMyCollection,
    gotoNews,
    gotoRegister,
    gotoMyPage,
    gotoPurchaseHistory,
  } = props;

  return (
    <header className="ol-header d-flex align-items-center justify-content-between">
      <div
        className="logo"
        onClick={() => {
          history.push('/');
        }}
      >
        <svg
          id="Logo_black"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="193.73"
          height="42.199"
          viewBox="0 0 193.73 42.199"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_4764"
                data-name="Rectangle 4764"
                width="193.73"
                height="42.199"
                fill="none"
              />
            </clipPath>
          </defs>
          <g id="Group_7430" data-name="Group 7430" clipPath="url(#clip-path)">
            <path
              id="Path_57587"
              data-name="Path 57587"
              d="M59.36,5.447A22.96,22.96,0,0,0,63.49,9.578a22.959,22.959,0,0,0-4.131,4.131,22.96,22.96,0,0,0-4.131-4.131A22.96,22.96,0,0,0,59.36,5.447M59.36,0A.483.483,0,0,0,58.9.319a17.851,17.851,0,0,1-8.8,8.8.486.486,0,0,0,0,.912,17.852,17.852,0,0,1,8.8,8.8.486.486,0,0,0,.912,0,17.852,17.852,0,0,1,8.8-8.8.486.486,0,0,0,0-.912,17.851,17.851,0,0,1-8.8-8.8A.484.484,0,0,0,59.36,0"
              transform="translate(-21.854 0)"
            />
            <path
              id="Path_57588"
              data-name="Path 57588"
              d="M76.74,31.15a19.251,19.251,0,0,0,2.3,2.3,19.289,19.289,0,0,0-2.3,2.3,19.288,19.288,0,0,0-2.3-2.3,19.251,19.251,0,0,0,2.3-2.3m0-5.144a.478.478,0,0,0-.445.3A14.368,14.368,0,0,1,69.6,33a.48.48,0,0,0,0,.89,14.366,14.366,0,0,1,6.7,6.7.48.48,0,0,0,.89,0,14.366,14.366,0,0,1,6.7-6.7.48.48,0,0,0,0-.89,14.368,14.368,0,0,1-6.7-6.7.478.478,0,0,0-.445-.3"
              transform="translate(-30.422 -11.417)"
            />
            <path
              id="Path_57589"
              data-name="Path 57589"
              d="M206.64,25.755h0L227.058,6.544H207.486L187.069,25.755l9.783,9.209L207.59,45.072h19.571Z"
              transform="translate(-82.123 -2.873)"
            />
            <path
              id="Path_57590"
              data-name="Path 57590"
              d="M328.825,39.132l-8.62-8.324h14.1V44.42Z"
              transform="translate(-140.57 -13.525)"
            />
            <path
              id="Path_57591"
              data-name="Path 57591"
              d="M328.825,14.869,320.2,6.545h14.1V20.156Z"
              transform="translate(-140.57 -2.873)"
            />
            <path
              id="Path_57592"
              data-name="Path 57592"
              d="M301.236,33.768h-14.05V6.545H271.322V33.768l.07.072,11.522,11.126h29.919Z"
              transform="translate(-119.11 -2.873)"
            />
            <path
              id="Path_57593"
              data-name="Path 57593"
              d="M15.864,9.152H2.729L0,46.217H13.065l1.418-18.769L16.551,29.7l.392.428,2.992,3.262L31.6,46.11H49.76Z"
              transform="translate(0 -4.018)"
            />
            <path
              id="Path_57594"
              data-name="Path 57594"
              d="M145,39.129,134.74,28.5,145.1,17.774h.024V6.545H105.013V17.774h21.876l-26.362,27.3h18.212l6.895-7.14,6.895,7.14H145Z"
              transform="translate(-44.131 -2.873)"
            />
          </g>
        </svg>
      </div>
      <div className="menu d-flex">
        {isLogin ? (
          <div
            className="my-collection d-flex align-items-center justify-content-center"
            onClick={gotoMyCollection}
          >
            <svg
              id="card_giftcard_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#f55076"
            >
              <path id="Path_53318" data-name="Path 53318" d="M0,0H24V24H0Z" fill="none" />
              <path
                id="Path_53319"
                data-name="Path 53319"
                d="M20,6H17.82A2.993,2.993,0,0,0,12.5,3.35l-.5.67-.5-.68A2.994,2.994,0,0,0,6.18,6H4A1.985,1.985,0,0,0,2.01,8L2,19a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V8A1.993,1.993,0,0,0,20,6ZM15,4a1,1,0,1,1-1,1A1,1,0,0,1,15,4ZM9,4A1,1,0,1,1,8,5,1,1,0,0,1,9,4ZM20,19H4V17H20Zm0-5H4V8H9.08L7,10.83,8.62,12,11,8.76,12,7.4l1,1.36L15.38,12,17,10.83,14.92,8H20Z"
              />
            </svg>
            {t('menu.myCollection')}
          </div>
        ) : (
          <div />
        )}
        <div
          className="user-guide d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/guide');
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="レイヤー_1" data-name="レイヤー 1" transform="translate(-84 86)">
              <path
                id="Path_52141"
                data-name="Path 52141"
                d="M0,0H24V24H0Z"
                transform="translate(84 -86)"
                fill="none"
              />
              <path
                id="Path_52551"
                data-name="Path 52551"
                d="M32.39,20.541l5.632-5.631a1.158,1.158,0,0,0,.337-.813V4.169a1.152,1.152,0,0,0-1.967-.813l-4,4Z"
                transform="translate(63.641 -84.279)"
                fill="#40b569"
              />
              <path
                id="Path_52139"
                data-name="Path 52139"
                d="M9.783,19.718,14.6,14.9a1.158,1.158,0,0,0,.337-.813V4.161a1.152,1.152,0,0,0-1.967-.813l-4,4-4-4A1.152,1.152,0,0,0,3,4.161v9.926a1.158,1.158,0,0,0,.337.813l4.819,4.819a1.148,1.148,0,0,0,1.627,0Z"
                transform="translate(87 -84.271)"
                fill="none"
                stroke="#40b569"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
          </svg>

          {t('menu.userGuide')}
        </div>
        <div className="wrap-notify">
          <div
            className="notice d-flex align-items-center justify-content-center"
            onClick={gotoNews}
          >
            <svg
              id="notifications_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ff6c00"
            >
              <path id="Path_52137" data-name="Path 52137" d="M0,0H24V24H0Z" fill="none" />
              <path
                id="Path_52138"
                data-name="Path 52138"
                d="M12,22a2.006,2.006,0,0,0,2-2H10A2.006,2.006,0,0,0,12,22Zm6-6V11c0-3.07-1.63-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.64,5.36,6,7.92,6,11v5L4,18v1H20V18Zm-2,1H8V11c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5Z"
              />
            </svg>

            {t('menu.news')}
          </div>
          {isLogin && <NotifyNews />}
        </div>

        <div
          className="header-list-lottery d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/lottery');
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.371"
            height="16.695"
            viewBox="0 0 22.371 16.695"
          >
            <g
              id="Rectangle_4671"
              data-name="Rectangle 4671"
              transform="translate(1.371)"
              fill="none"
              stroke="#f55076"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
            >
              <rect width={21} height={15} stroke="none" />
              <rect x={1} y={1} width={19} height={13} fill="none" />
            </g>
            <path
              id="Path_57534"
              data-name="Path 57534"
              d="M1.678-.728l.549-2.2,7.1,11.254L0,6Z"
              transform="matrix(0.966, -0.259, 0.259, 0.966, 0.758, 8.655)"
              fill="#f55076"
            />
            <path
              id="Path_57535"
              data-name="Path 57535"
              d="M-8276.189,699.736l12.112,11.51"
              transform="translate(8278.859 -697)"
              fill="none"
              stroke="#f55076"
              strokeWidth={2}
            />
          </svg>

          {t('menu.listLottery')}
        </div>

        {!isLogin ? (
          <div
            className="register-account d-flex align-items-center justify-content-center"
            onClick={gotoRegister}
          >
            <svg
              id="Component_19_7"
              data-name="Component 19 – 7"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width={24}
              height={24}
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_4781"
                    data-name="Rectangle 4781"
                    width={24}
                    height={24}
                    fill="none"
                  />
                </clipPath>
              </defs>
              <g id="Group_7488" data-name="Group 7488" clipPath="url(#clip-path)">
                <path
                  id="Path_57598"
                  data-name="Path 57598"
                  d="M17.25,7.357A5.25,5.25,0,1,1,12,2.107a5.25,5.25,0,0,1,5.25,5.25"
                  fill="#2f92d0"
                />
                <path
                  id="Path_57599"
                  data-name="Path 57599"
                  d="M20.009,21.893H3.991c0-3.427,3.586-8.608,8.009-8.608s8.009,5.181,8.009,8.608"
                  fill="#2f92d0"
                />
              </g>
            </svg>
            {t('menu.register')}
          </div>
        ) : (
          <div />
        )}
        <div className="sub-menu d-flex align-items-center justify-content-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="menu" transform="translate(-1069 -68)">
              <rect id="Box" width="24" height="24" transform="translate(1069 68)" fill="none" />
              <path
                id="Path_417"
                data-name="Path 417"
                d="M604-1400h18v-2H604Zm0-5h18v-2H604Zm0-7v2h18v-2Z"
                transform="translate(468 1486)"
                fill="#464646"
              />
            </g>
          </svg>
          {t('menu')}
          <div className="sub-menu-wrapper">
            <div id="icon-wrapper">
              <svg
                id="dropdown-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12"
                viewBox="0 0 18 12"
              >
                <path
                  id="Polygon_5"
                  data-name="Polygon 5"
                  d="M9,0l9,12H0Z"
                  transform="translate(18 12) rotate(180)"
                  fill="#464646"
                  stroke="#fff"
                  strokeWidth={2}
                />
              </svg>
            </div>
            {isLogin ? (
              <div className="sub-menu-item-wrap">
                <div className="sub-menu-item" onClick={gotoMyPage}>
                  {t('menu.myPage')}
                </div>
                <div className="sub-menu-item" onClick={gotoPurchaseHistory}>
                  {t('menu.purchaseHistory')}
                </div>
                <div className="sub-menu-item" onClick={handleLogout}>
                  {t('logout')}
                </div>
              </div>
            ) : (
              <div className="sub-menu-item-wrap">
                <div className="sub-menu-item" onClick={gotoLogin}>
                  {t('login')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
