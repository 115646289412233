// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import olAxios from '../api/Interceptors';

export const MyCollectionApis = {
  getListLottery: (params) => {
    return olAxios.get('/collection', { params });
  },
  getCollectionPrize: (params) => {
    return olAxios.get('/collection/prize', { params });
  },

  getLotteryDetail: (params) => {
    return olAxios.get('/collection/detail', { params });
  },

  discardPrize: (params) => {
    return olAxios.post('/prize/cancel', params);
  },
  downloadDigitalPrize: (params) => {
    return olAxios.post('/prize-digital/download', params);
  },
};
