// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './styles.scss';

function TopTitleKuzipa() {
  return (
    <div className="top-title-kuzipa">
      <div className="icon-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.64"
          height="34.106"
          viewBox="0 0 25.64 34.106"
        >
          <path
            id="Path_54282"
            data-name="Path 54282"
            d="M0,0H7.2L1.44,35.992H0Z"
            transform="matrix(0.839, -0.545, 0.545, 0.839, 0, 3.921)"
            fill="#f9be2e"
          />
        </svg>
      </div>

      <div className="text-kuzipa">
        <div className="title-logo-PC">
          <svg
            id="Logo_yellow"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="164.371"
            height="35.804"
            viewBox="0 0 164.371 35.804"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_4762"
                  data-name="Rectangle 4762"
                  width="164.371"
                  height="35.804"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g id="Group_7426" data-name="Group 7426" clipPath="url(#clip-path)">
              <path
                id="Path_57571"
                data-name="Path 57571"
                d="M57.908,4.622a19.48,19.48,0,0,0,3.5,3.5,19.48,19.48,0,0,0-3.5,3.5,19.481,19.481,0,0,0-3.5-3.5,19.481,19.481,0,0,0,3.5-3.5m0-4.622a.41.41,0,0,0-.387.27,15.145,15.145,0,0,1-7.47,7.469.412.412,0,0,0,0,.774,15.146,15.146,0,0,1,7.47,7.47.412.412,0,0,0,.774,0,15.146,15.146,0,0,1,7.47-7.47.412.412,0,0,0,0-.774A15.145,15.145,0,0,1,58.3.27.411.411,0,0,0,57.908,0"
                transform="translate(-26.086 0)"
                fill="#ffb400"
              />
              <path
                id="Path_57572"
                data-name="Path 57572"
                d="M75.613,30.37a16.334,16.334,0,0,0,1.95,1.95,16.364,16.364,0,0,0-1.95,1.95,16.363,16.363,0,0,0-1.95-1.95,16.333,16.333,0,0,0,1.95-1.95m0-4.364a.406.406,0,0,0-.377.255,12.191,12.191,0,0,1-5.682,5.682.407.407,0,0,0,0,.755,12.189,12.189,0,0,1,5.682,5.682.407.407,0,0,0,.755,0A12.189,12.189,0,0,1,81.672,32.7a.407.407,0,0,0,0-.755,12.191,12.191,0,0,1-5.682-5.682.406.406,0,0,0-.377-.255"
                transform="translate(-36.313 -13.628)"
                fill="#ffb400"
              />
              <path
                id="Path_57573"
                data-name="Path 57573"
                d="M203.674,22.844h0L221,6.544H204.392l-17.323,16.3,8.3,7.814,9.111,8.576h16.605Z"
                transform="translate(-98.027 -3.429)"
                fill="#ffb400"
              />
              <path
                id="Path_57574"
                data-name="Path 57574"
                d="M327.518,37.871,320.2,30.808h11.96V42.357Z"
                transform="translate(-167.792 -16.144)"
                fill="#ffb400"
              />
              <path
                id="Path_57575"
                data-name="Path 57575"
                d="M327.518,13.607,320.2,6.545h11.96V18.094Z"
                transform="translate(-167.792 -3.43)"
                fill="#ffb400"
              />
              <path
                id="Path_57576"
                data-name="Path 57576"
                d="M296.7,29.642H284.782V6.545h-13.46v23.1l.059.061,9.776,9.44h25.385Z"
                transform="translate(-142.177 -3.43)"
                fill="#ffb400"
              />
              <path
                id="Path_57577"
                data-name="Path 57577"
                d="M13.46,9.152H2.315L0,40.6H11.085l1.2-15.925,1.755,1.914.333.363,2.538,2.767L26.809,40.51h15.41Z"
                transform="translate(0 -4.796)"
                fill="#ffb400"
              />
              <path
                id="Path_57578"
                data-name="Path 57578"
                d="M138.262,34.191l-8.707-9.016,8.791-9.1h.02V6.545H104.333v9.527h18.561L100.527,39.234H115.98l5.85-6.058,5.85,6.058h10.583Z"
                transform="translate(-52.678 -3.43)"
                fill="#ffb400"
              />
            </g>
          </svg>
        </div>
        <div className="title-logo-SP">
          <svg
            id="Logo_yellow"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="128.938"
            height="28.086"
            viewBox="0 0 128.938 28.086"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_4762"
                  data-name="Rectangle 4762"
                  width="128.938"
                  height="28.086"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g id="Group_7426" data-name="Group 7426" clipPath="url(#clip-path)">
              <path
                id="Path_57571"
                data-name="Path 57571"
                d="M56.156,3.625a15.281,15.281,0,0,0,2.749,2.749,15.281,15.281,0,0,0-2.749,2.749,15.281,15.281,0,0,0-2.749-2.749,15.281,15.281,0,0,0,2.749-2.749m0-3.625a.322.322,0,0,0-.3.212,11.881,11.881,0,0,1-5.859,5.859.323.323,0,0,0,0,.607,11.881,11.881,0,0,1,5.859,5.859.323.323,0,0,0,.607,0,11.881,11.881,0,0,1,5.859-5.859.323.323,0,0,0,0-.607A11.881,11.881,0,0,1,56.46.212a.322.322,0,0,0-.3-.212"
                transform="translate(-31.194 0)"
                fill="#ffb400"
              />
              <path
                id="Path_57572"
                data-name="Path 57572"
                d="M74.251,29.429a12.813,12.813,0,0,0,1.53,1.53,12.838,12.838,0,0,0-1.53,1.529,12.837,12.837,0,0,0-1.53-1.529,12.812,12.812,0,0,0,1.53-1.53m0-3.423a.318.318,0,0,0-.3.2A9.563,9.563,0,0,1,69.5,30.663a.319.319,0,0,0,0,.592,9.561,9.561,0,0,1,4.457,4.457.319.319,0,0,0,.592,0A9.561,9.561,0,0,1,79,31.255a.319.319,0,0,0,0-.592,9.563,9.563,0,0,1-4.457-4.457.318.318,0,0,0-.3-.2"
                transform="translate(-43.424 -16.296)"
                fill="#ffb400"
              />
              <path
                id="Path_57573"
                data-name="Path 57573"
                d="M200.095,19.33h0L213.684,6.544H200.658L187.069,19.33l6.511,6.129,7.147,6.727h13.026Z"
                transform="translate(-117.222 -4.101)"
                fill="#ffb400"
              />
              <path
                id="Path_57574"
                data-name="Path 57574"
                d="M325.941,36.348l-5.737-5.54h9.382v9.059Z"
                transform="translate(-200.647 -19.305)"
                fill="#ffb400"
              />
              <path
                id="Path_57575"
                data-name="Path 57575"
                d="M325.941,12.085,320.2,6.545h9.382V15.6Z"
                transform="translate(-200.647 -4.101)"
                fill="#ffb400"
              />
              <path
                id="Path_57576"
                data-name="Path 57576"
                d="M291.231,24.663H281.88V6.545H271.322V24.663l.046.048,7.668,7.405h19.913Z"
                transform="translate(-170.016 -4.101)"
                fill="#ffb400"
              />
              <path
                id="Path_57577"
                data-name="Path 57577"
                d="M10.558,9.152H1.816L0,33.821H8.7l.944-12.492,1.377,1.5.261.285,1.991,2.171L21.03,33.75H33.118Z"
                transform="translate(0 -5.735)"
                fill="#ffb400"
              />
              <path
                id="Path_57578"
                data-name="Path 57578"
                d="M130.128,28.232l-6.83-7.073,6.9-7.141h.016V6.545h-26.7v7.474h14.56L100.527,32.187h12.121l4.589-4.752,4.589,4.752h8.3Z"
                transform="translate(-62.993 -4.101)"
                fill="#ffb400"
              />
            </g>
          </svg>
        </div>

        <div className="title-welcome">へようこそ</div>
      </div>
      <div className="icon-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.64"
          height="34.106"
          viewBox="0 0 25.64 34.106"
        >
          <path
            id="Path_54281"
            data-name="Path 54281"
            d="M0,35.992H7.2L1.44,0H0Z"
            transform="matrix(-0.839, -0.545, 0.545, -0.839, 6.037, 34.106)"
            fill="#f9be2e"
          />
        </svg>
      </div>
    </div>
  );
}

export default TopTitleKuzipa;
