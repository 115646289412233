// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { OUT_OF_STOCK, PERIOD_CURRENT, PERIOD_EXPIRED } from '../../../constants';
import periodCurrentIcon from '../../../../assets/imgs/lotteryDetail/periodCurrentIcon.png';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

const Content = styled.div`
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 20px;
  width: 608px;
  margin: 0 auto;
  margin-top: 30px !important;
  padding-bottom: 50px;
  margin-bottom: 57px;
  color: #3e3e3e;
  th {
    padding: 2px 0 2px 0 !important;
    border-bottom-width: 0 !important;
    width: 0px;
  }
  td {
    padding: 2px 0 2px 0 !important;
    border-bottom-width: 0 !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
    line-height: 16px;
    padding-bottom: 12px;
    margin-bottom: 40px;
    width: 283px;
  }
`;

export default function WChanceCampaignPeriod(props) {
  const { t } = useTranslation();
  const { prizes } = props;

  const schedulePeriod = (status, quantity) => {
    let result = '';
    if (status === PERIOD_EXPIRED) {
      result = 'background-expired';
    } else if (status === PERIOD_CURRENT && quantity <= OUT_OF_STOCK) {
      result = 'background-expired background-current';
    } else if (status === PERIOD_CURRENT) {
      result = 'background-current';
    }
    return result;
  };

  return (
    <>
      <div className="w-chance-campaign-period-title">
        <h3>{t('wChangeCampaignTitle')}</h3>
      </div>

      <p className="des-campaign-period">{t('descriptionCampaignPeriod')}</p>

      {prizes.map((prize, index) => {
        return (
          <div
            className={`schedule-period ${schedulePeriod(prize.status, prize.quantity)}`}
            key={index}
          >
            <p className="mb-0 title">{prize.title} </p>

            {prize.status === PERIOD_CURRENT && (
              <img src={periodCurrentIcon} alt="img" className="title-tag" />
            )}

            {prize.status === PERIOD_EXPIRED && <p className="text">{t('endOfLotteryPeriod')}</p>}
            {prize.status === PERIOD_CURRENT && prize.quantity <= OUT_OF_STOCK && (
              <p className="text">{t('outOfStock')}</p>
            )}

            <p className="mb-0 date-period">
              {prize.period_start_date_format} ～ {prize.period_end_date_format}
            </p>
          </div>
        );
      })}
      <Content>
        <Table>
          <tbody>
            <tr>
              <th>※</th>
              <td>
                ＋On Challenge について <br />
                購入したくじでもらえる商品とは別に、自動抽せんされて当せんする可能性のある賞です。
              </td>
            </tr>
            <tr>
              <th>※</th>
              <td>くじ1枚ごとに抽せんを行います。</td>
            </tr>
            <tr>
              <th>※</th>
              <td>
                購入したクジの後に、「＋Onくじ券」が出て、「アタリ」が出たら＋On Challenge賞ゲット！
              </td>
            </tr>
            <tr>
              <th>※</th>
              <td>当せんされた商品は、通常のくじの当せん商品と同梱し、お届けいたします。</td>
            </tr>
            <tr>
              <th>※</th>
              <td>キャンペーン期間中でも、規定数に達し次第終了いたします。</td>
            </tr>
            <tr>
              <th>※</th>
              <td>
                抽せんは購入した枚数分行いますが、演出の関係上「＋Onくじ券」の表示はすべての抽せん結果をまとめたものとなりますのでご留意ください。
              </td>
            </tr>
            <tr>
              <th>※</th>
              <td>＋On Challengeは完全抽せんです。</td>
            </tr>
            <tr>
              <th>※</th>
              <td>
                規定数は最大数であり、当せん者数が規定数に達しない場合でも、抽せん期間が終了すればそのまま
                終了いたします。
              </td>
            </tr>
          </tbody>
        </Table>
      </Content>
    </>
  );
}
